
import React from "react";

export default function UserBookingStatus({status}) {

    if (status === 'standby') {
        return <button className="btn">new</button>;
      } else if (status === 'start') {
        return <button className="btn">on route</button>;
      } else if (status === 'reached') {
        return <button className="btn">reached</button>;
      } else {
        return <p></p>;
      }
}