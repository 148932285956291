import React from "react";
import Search from "../../components/home/search/search";
import Pagination from "../../components/users/customers/Pagination";
import "./customers.css";
import { useState,useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/BaseUrl";
import AlertMessage from "../../components/alerts";
export default function Pcustomers() {
const [currentPage, setCurrentPage] = useState(1);
const [lastPage, setLastPage] = useState(0);
const [Customerpayments,SetCustomerPayments]=useState([]);
const [loading, setLoading] = useState(true);
  //get payments using axios
  function SetHeader() {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  }
  SetHeader();
  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/api/payments/all?page=${currentPage}`)
      .then(res => {
        if (res.data.records.errors?.length) {
          AlertMessage('error', 'Unable to fetch data');
        } else {
          SetCustomerPayments(res.data.records.data);
          setCurrentPage(res.data.records.current_page);
          setLastPage(res.data.records.last_page);
          console.log(res.data.records.data);
        }
      })
      .catch(error => {
        console.error('Error fetching service data:', error);
        AlertMessage('error', 'Unable to fetch data');
      });
    setLoading(false);
  }, [currentPage]);
  
  
  
  
  return (
    <div className="allservices">
      <Search />
      {/* breedcamp */}
      <div>
        <span style={{ color: "#7A7A7A" }} className="pl-1">
          Payments {">"} customers{" "}
        </span>
      </div>
      {/* table list for all services */}

      <div className=" mt-3">
        <div className="card card-outline-secondary">
          {/* heading */}
          <div className="card-header">
            <h3 className=" mb-0 ">Customer Payment</h3>
          </div>
          {/* body */}
          <div>
            {/* create a table for list of sevices */}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Order ID</th>
                  {/* <th>Service ID</th> */}
                  <th>Amount</th>
                  <th>Card Number</th>
                  <th>Payment Method</th>
                  <th>Service Provider</th>
                  <th>Date</th>
                  <th>Contact no</th>
                </tr>
              </thead>
              <tbody>
                {/* item row 1 */}
                {Customerpayments.map((payment, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{payment.client && payment.client.username !==null ? payment.client.username:'-'}</td>
                  <td>{payment.booking_id !==null ? payment.booking_id:'-'}</td>
                  {/* <td>Acrylic Nail art</td> */}
                  <td>${payment.booking && payment.booking.total_price !==null ? payment.booking.total_price:'0'}</td>
                  <td>378282246310005</td>
                  <td>Clover</td>
                  <td>{payment.provider && payment.provider.username !==null ? payment.provider.username:'-'}</td>
                  <td>{payment.created_at !==null ? payment.created_at:'-'}</td>
                  <td>{payment.client && payment.client.phone_no !==null ? payment.client.phone_no:'-'}</td>
                  {/* <td>5</td> */}
                  </tr>
                  ))}
                  
                
              </tbody>
            </table>
          </div>
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} lastPage={lastPage} />
        </div>
      </div>
    </div>
  );
}
