import "./../dailydeal.css";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../utils/BaseUrl";
import { useEffect } from "react";
import AlertMessage from "../../../components/alerts";
import Select from 'react-select';

function Dropdown({ setSelectedServices }) {
  const [loader, setLoader] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);

  useEffect(() => {
    setLoader(true);
    axios.get(`${baseUrl}/api/services/allServices`)
      .then(res => {
        console.log(res);
        if (res.data.errors?.length) {
          AlertMessage('error', "Unable to Fetch On-going deals");
          setLoader(false);
        } else {
          setDropdown(res.data.records);
          setLoader(false);
        }
      });
  }, []);

  const handleSelectChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map(option => option.value);
    setSelectedServiceIds(selectedIds);
  };

  useEffect(() => {
    const selectedServices = dropdown.filter(service => selectedServiceIds.includes(service.id));
    setSelectedServices(selectedServices);
  }, [selectedServiceIds, dropdown, setSelectedServices]);

  const options = dropdown.map(service => ({
    value: service.id,
    label: service.name,
  }));

  return (
    <div className="form-group">
      <label>Select Services</label>
      <Select
        isMulti
        value={options.filter(option => selectedServiceIds.includes(option.value))}
        options={options}
        onChange={handleSelectChange}
      />
    </div>
  );
}

export default Dropdown;