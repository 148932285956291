import React from "react";
import apic from "../services/dealimg.png";
import './styles.css'
export default function DealOffer(props) {

  return (
    <div className="card  dealWrapper ongoing-deal m-2  ">
      <img src={apic} alt="Card" />
      <span className="circle-div-right">
        <i className="fa fa-trash-o " aria-hidden="true"></i>
      </span>
      <span className="circle-div-left">
        <i className="fa fa-pencil " aria-hidden="true"></i>
      </span>

      {/* <p className="bg-dark label pl-2">Order no : 23221</p> */}
      <p className="heading">{props.deal.name}</p>
      <p className="sub-heading pt-1">Service</p>
      {props.deal.services && props.deal.services.length > 0 ? (
  props.deal.services.map((service, index) => (
    <p className="normal" key={index}>
      <b>{service.name},</b>
    </p>
  ))
) : (
  <p className="normal">No services available</p>
)}
      {/* <span className="pt-1">
        <span className="sub-heading ">Originalprice :</span>
        <span className="float-right pr-2">$150</span>
      </span> */}
      <span className="pt-1">
        <span className="sub-heading">Discount : </span>
        <span className="float-right pr-2">{props.deal.discount_percentage
        }%</span>
      </span>
      {/* <span className="pt-1">
        <span className="sub-heading">Discounted price : </span>
        <span className="float-right pr-2">$135</span>
      </span> */}
      <span className="pt-1">
        <span className="sub-heading">Duration : </span>
        <span className="float-right pr-2">{props.deal.start_date
        } - {props.deal.end_date
          }</span>
      </span>
      {/* <Link to="#" className="btn btn-primary">
            Go somewhere
          </Link> */}
    </div>
  );
}
