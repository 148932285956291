import "./App.css";
import Dashborad from "./Dashboard";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";

function App() {
  // const location = useLocation();
  // const history = useHistory();

  // function getToken() {
  //   return localStorage.getItem("token");
  // }

  // const isAuthorized = () => {
  //   return getToken() !== null ? true : false;
  // };
  // useEffect(() => {
  //   const authorized = isAuthorized();

  //   if (authorized && location.pathname == "/login") {
  //     history.push("/dashboard");
  //   }
  //   if (!authorized && location.pathname !== "/login") {
  //     history.push("/login");
  //   }
  //   // else {
  //   //   // history.push("/login");
  //   //   <Redirect to="login" />;
  //   // }
  // }, [history, location]);
  return (
    <div className="app">
      <Dashborad />
    </div>
  );
}

export default App;
