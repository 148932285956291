import React from "react";
import Search from "../../components/home/search/search";
import Pagination from "./Pagination";
import "./allservice.css";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import AlertMessage from "../../components/alerts";
import { baseUrl } from "../../utils/BaseUrl";
import SpinnerLoader from "../../components/loaders/Spiner";
import moment from "moment";
import EditService from "./Components/EditService";
import { Modal,Button } from "react-bootstrap";
export default function AllServices() {
  const [editServiceId, setEditServiceId] = useState(null);
  const [services, setServices] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [dependecy, setDependency] = useState(0)
  const [show, setShow] = useState(false);
  const [image, setImage] = useState();
  const [name, setName] = useState();
  const  [amount, setAmount] = useState();
  function SetHeader() {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  }
  SetHeader();
  useEffect(() => {
    fetchServiceData();
  }, [currentPage]);

  const fetchServiceData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/services/all?page=${currentPage}`);
      if (response.data.errors?.length) {
        AlertMessage('error', 'Unable to fetch data');
      } else {
        setServices(response.data.records.data);
        setCurrentPage(response.data.records.current_page);
        setLastPage(response.data.records.last_page);
      }
    } catch (error) {
      console.error('Error fetching service data:', error);
      AlertMessage('error', 'Unable to fetch data');
    }
    setLoading(false);
  };

  const handleServiceUpdate = () => {
    fetchServiceData();
  };
  const addService = () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('amount', amount);
    formData.append('image_url', image);
    axios.post(`${baseUrl}/api/services/create`, formData)
      .then(res => {
        if (res.data.errors?.length) {
          AlertMessage('error', 'Unable to add service')
        }
        else {
          AlertMessage('success', res.data._metadata.message)
          setDependency(dependecy + 1)
          setShow(false)
        }
      })
  }
  const DeleteService = (id) => {
    const services_id = id
    axios.post(`${baseUrl}/api/services/delete`, { services_id })
      .then(res => {
        if (res.data.errors?.length) { AlertMessage('error', 'Unable to delete') }
        else {
          AlertMessage('success', res.data._metadata.message)
          setDependency(dependecy + 1)
          fetchServiceData();
        }
      })
  }
  const handleModal = () => {
    setShow(!show)
  }

  return (
    <div className="allservices">
    {/* bootstrap modal */}
    <Modal show={show} onHide={handleModal}>
        <Modal.Header closeButton>
            <Modal.Title>Add New Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form>
                <div className="form-group">
                    <label htmlFor="ServiceName">Service Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="ServiceName"
                         onChange={(e) => {
                             setName( e.target.value );
                       }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="ServiceAmount">Amount</label>
                    <input
                        type="text"
                        className="form-control"
                        id="ServiceAmount"
                        onChange={(e) => {
                            setAmount(e.target.value);
                        }}
                    />
                </div>
                <div className="form-group">
                <div className="form-group col-md-6">
                            <label htmlFor="inputCity">Add Image</label>

                            <div className="image-upload">
                              {" "}
                              <label htmlFor="file_upload">
                                {" "}
                                <img src="" alt="" className="uploaded-image" />
                                <div className="h-100">
                                  <div className="dplay-tbl">
                                    <div className="dplay-tbl-cell">
                                      {" "}
                                      <i
                                        className="fa fa-camera"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                    <input
                          data-required="image"
                          type="file"
                          name="image_name"
                          id="file_upload"
                          className="image-input"
                          data-traget-resolution="image_resolution"
                          onChange={(e) => { setImage(e.target.files[0]) }}
                     />
                     </label>{" "}
                </div> </div>
                          </div>
              
              
                </form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleModal}>
                Close
            </Button>
            <Button variant="primary" onClick={addService}>
                Save Changes
            </Button>
        </Modal.Footer>
    </Modal>

    <Search />
      

      <div className=" mt-3">
        <div className="card card-outline-secondary">
          {/* heading */}
          <div
                className="card-header"
                style={{
                    display: "flex", justifyContent: "space-between",
                }}
            >
                <h3 className=" mb-0 ">All Services</h3>
                <button className="btn-add-artist" onClick={handleModal}>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    <span>Add new Service</span>
                </button>
            </div>
          {/* body */}
          <div className="all-services-list">
            {/* create a table for list of sevices */}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Service Image</th>
                  <th>Service</th>
                  <th>Amount</th>
                  <th>created_at</th>
                  <th>updated_at</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? <div style={{ height: '455px', padding: '0% 550%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerLoader /></div> : services?.map((service, index) => (
                  < tr key={service.id}>
                    <td>{index + 1}</td>
                     <td><img src={`${service?.image}`} alt="" style={{width:"50px",height:"50px"}} /></td> 
                    <td>{service.name}</td>
                    <td>{service.amount}</td>
                    <td>{moment(service.created_at).format("DD/MM/YYYY")}</td>
                    <td>{moment(service.updated_at).format("DD/MM/YYYY")}</td>
                    <td>
                      <span>
                      <div className="circle-services-edit" style={{marginLeft:"25px",cursor:'pointer'}}>
                    <i
                      className="fa fa-pencil"
                      aria-hidden="true"
                      onClick={() => setEditServiceId(service.id)}
                    ></i>
                  </div>
                      </span>
                      <span>
                        <div className="circle-services-cross" style={{cursor:'pointer'}}>
                          <i className="fa fa-times" aria-hidden="true" onClick={() => { DeleteService(service.id) }}></i>
                        </div>
                      </span>
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
            {editServiceId && (
        <EditService
          serviceId={editServiceId}
          onClose={() => setEditServiceId(null)}
          onServiceUpdate={() => handleServiceUpdate()}
        />
      )}
          </div>
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} lastPage={lastPage} />
        </div>
      </div>
    </div >
  );
}

