import React from "react";
import nail from "../artists/nail.png";
export default function PortfolioItem(props) {
 
  return (
    <div className="artist-portfolio-slider-item">
      <h5>{props && props.portfolio && props.portfolio.title? props.portfolio.title : 'Acrylic paint and reshape'}</h5>
      {props && props.portfolio && props.portfolio.image_url? 
      <img src={`https://artist.nail2u.net/${props?.portfolio?.image_url}`} alt="" />
      :<img src={nail} alt="" />}
      <div className="artist-portfolio-slider-item-info">
        <div>
          <h5>Order ID</h5>
          <p>010242</p>
        </div>
        <div>
          <h5>Service ID</h5>
          <p>010242</p>
        </div>
      </div>
      <h5 className="pl-4">Client Name:</h5>
      <p className="pl-4">Cameron Williamson</p>
    </div>
  );
}
