import React from "react";
import rated from "../customers/rated.png";
import pic from "../customers/pic.png"
import "./FavouriteItem.css"
import { Link } from 'react-router-dom';
import unrated from "../customers/unrated.png";
export default function FavouriteItem(props) {
 
 function StarRating(props) {
    const avgRating = props.avgRating;
    const fullStars = Math.floor(avgRating);
    const hasHalfStar = avgRating - fullStars >= 0.5;
    const noStars = avgRating === null || avgRating === undefined || avgRating === 0;
    const starIcons = [];
    
    for (let i = 0; i < fullStars; i++) {
      starIcons.push(<img key={i} className="imgIcon" src={rated} alt="" />);
    }
    if (noStars) {
      for (let i = 0; i < 4; i++) {
        starIcons.push(<img key={i} className="imgIcon" src={rated} alt="" />);
      }
    }
    
    if (hasHalfStar) {
      starIcons.push(<img key="half" className="imgIcon" src={unrated} alt="" />);
    }
    
    while (starIcons.length < 5) {
      starIcons.push(<img key={starIcons.length} className="imgIcon" src={unrated} alt="" />);
    }
    
    return (
      <div>
        {starIcons}
      </div>
    );
  } 
   return (
    <div className="flex-container">
      <div className="favourite-slider-item">
        <img className="img" src={pic} alt="" />
        <Link to={`/dashboard/artists/artist-profile/${props.artist?.id}`}>
          <h5>
            {props && props.artist ? props.artist?.username:'Shailny Cordon'}
          </h5>     
        </Link>
        <h5>{props && props.artist ? props.artist?.avg_rating.toFixed(1):'4.0'}</h5>
        <div>
       <StarRating avgRating={props.artist?.avg_rating} />
      </div>
        <div className="circle">
          <i className="fa fa-heart" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
}
