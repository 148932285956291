export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const INVALID_CREDENTIALS = "INVALID_CREDENTIALS";




//dashboard
export const GET_DETAILS = "GET_DETAILS";

//artist
export const GET_ALL_ARTIST = "GET_ALL_ARTIST";

//artist-profile
export const SET_ARTIST_ID = "SET_ARTIST_ID";


//settings
export const GENERAL_SETTING = "GENERAL_SETTING";
export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

