import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from "../../../utils/BaseUrl";
import rating from "../artistDetail/rating.png";
import AlertMessage from "../../../components/alerts";
import SpinnerLoader from "../../loaders/Spiner";
const Reviews = () => {
  const [loading, setLoading] = useState(true);
  const [overallRating, setOverallRating] = useState(0);
  const [customerReviews, setCustomerReviews] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/api/rating/all`)
      .then(res => {
        if (res.data && res.data.records) {
          setOverallRating(res.data.records.overall_rating);
          if (Array.isArray(res.data.records.ratings)) {
            setCustomerReviews(res.data.records.ratings);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        AlertMessage('error', 'Unable to fetch data');
        setLoading(false);
      });
  }, []);

  return (
    <div className="col-4 artist-detail-main customer-review-main-div">
      <div className="container-fluid border-bottom pt-1">
        <div className="row">
          <div className="col-7 customer-review">
            <span>Customer's Reviews</span>
          </div>
          <div className="col-5 customer-overall-rating">
            <span>{overallRating}</span>
            <img src={rating} alt="" />
            <p>overall rating</p>
          </div>
        </div>
      </div>
      {loading ? (
         <center>
        <div style={{  transform: 'scaleX(1.5) scaleY(1.5)', height: '80px', paddingTop: '130px' }}><SpinnerLoader /></div>
        </center>
      ) : (
        customerReviews.map((review, index) => (
          <div key={index} className="row">
            <div className="col-9 customer-review-info">
              {/* <img src={`https://user.nail2u.net/${review.client.image_url}`} alt="profile" /> */}
              {review.client && review.client.image_url ? (
             <img src={`https://user.nail2u.net/${review.client.image_url}`} alt="profile" />
              ) : (
            <img src="" alt="default-profile" />
              )}
              
              {review.client && review.client.username ? (
              
              <span>{review.client.username}</span>
              ):  (
                <span></span>
                                )}

              <p>{review.review}</p>
            </div>
            <div className="col-3 customer-review-rating">
              <span>{review.rating}</span>
              {/* Assuming you have an image URL for the rating icon, you can use it here */}
              <img src={rating} alt="rating" />
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Reviews;
