import React from "react";

export default function UserBookingStatus({status}) {

    if (status === 'standby') {
        return <button className="btn-route-status" style={{backgroundColor:'green'}}>Ongoing</button>;
      } else if (status === 'start') {
        return <button className="btn-route-status">on route</button>;
      } else {
        return <p></p>;
      }
}