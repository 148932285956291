import React,{useState,useEffect} from 'react';
import chatpro from "../chat/chatpropic.png";
import { baseUrl } from "../../utils/BaseUrl";
import axios from "axios";
import AlertMessage from "../../components/alerts";
import './chat.css';
export default function ChatMessages(props) {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sender,setSender]=useState([]);
  const [receiver,setReceiver]=useState([]);
  console.log(props.senderId)
  function SetHeader() {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
}
  useEffect(() => {
    SetHeader();
    if(props.senderId && props.receiverId){
    axios.post(`${baseUrl}/api/message/all`,{sender_id:props.senderId,receiver_id:props.receiverId}).then((res) => {
      if (res.data.errors?.length) {
        AlertMessage('error', "Unbale to load data")

    }else
    {
      setMessages(res.data.records);
      setSender(res.data.records[0].sender);
      setReceiver(res.data.records[0].receiver);
      console.log(res.data.records[0].sender)
      console.log(res.data.records)
     
    }
    });}
  }, [props.senderId, props.receiverId]);
  
  return (
    <div className="col-7 scrollable">
      <div>
        { props.senderId && props.receiverId ?
        <div className="cus-to-art-chat-detail pr-4">
          <div className="cus-to-art-chat-detail-profile">
            <div className="cus-to-art-chat-detail-data">
              <img src={chatpro} alt="" />
              <h6>{sender.username}</h6>
            </div>
            <div className="line-cus-to-art-detail"></div>
            <div className="cus-to-art-chat-detail-data cus-to-art-adject">
              <img src={chatpro} alt="" />
              <h6>{receiver.username}</h6>
            </div>
          </div>
          <p>1 minute ago</p>
        </div>:<div></div>
}
        {messages.map((message) => (
          <div
            className={`${
              message.sender.id === sender.id
                ? "chatting-text-with-profile"
                : "chatting-text-with-profile cummunicator-detail-chatting"
            }`}
            key={message.id}
          >
            <img src={chatpro} alt="" />
            <div>
              <h6>{message.message}</h6>
              <p>1 minute ago</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  }
