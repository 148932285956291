import React, { useEffect, useState } from 'react';
import { Modal,Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../../utils/BaseUrl';
import moment from 'moment';
import AlertMessage from "../../../components/alerts";
const EditService = ({ serviceId, onClose , onServiceUpdate}) => {
  const [serviceData, setServiceData] = useState({
    name: '',
    amount: '',
    image: null,
  });
  const [loading, setLoading] = useState(true);
  const updateService = async () => {
    try {
      const formData = new FormData();
      formData.append('services_id', serviceId);
      formData.append('name', serviceData.name);
      formData.append('amount', serviceData.amount);
      formData.append('image_url', serviceData.image);
      const res = await axios.post(`${baseUrl}/api/services/edit`, formData);
      if (res.data.errors?.length) {
        AlertMessage('error', 'Unable to update service');
      } else {
        AlertMessage('success', res.data._metadata.message);
        onClose();
        onServiceUpdate();
      }
    } catch (error) {
      AlertMessage('error', 'Unable to update service');
    }
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setServiceData({ ...serviceData, image: selectedImage });
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/api/services/get-details/${serviceId}`)
      .then(res => {
        if (res.data) {
          setServiceData(res.data.records);
          setLoading(false);
        } else {
          console.error('No service data found');
          setLoading(false);
        }
       
      })
      .catch(error => {
        console.error('Error fetching service data:', error);
        setLoading(false);
      });
  }, [serviceId]);
  
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div>Loading...</div>
        ) : (
              <form>
              <div className="form-group">
                <label htmlFor="ServiceName">Service Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="ServiceName"
                  value={serviceData.name}
                  onChange={(e) => setServiceData({ ...serviceData, name: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ServiceAmount">Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="ServiceAmount"
                  value={serviceData.amount}
                  onChange={(e) => setServiceData({ ...serviceData, amount: e.target.value })}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputCity">Add Image</label>
                <div className="image-upload">
                  <label htmlFor="file_upload">
                    <img src="" alt="" className="uploaded-image" />
                    <div className="h-100">
                      <div className="dplay-tbl">
                        <div className="dplay-tbl-cell">
                          <i className="fa fa-camera" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <input
                      data-required="image"
                      type="file"
                      name="image_name"
                      id="file_upload"
                      className="image-input"
                      data-target-resolution="image_resolution"
                      onChange={handleImageChange}
                    />
                  </label>
                </div>
              </div>
            </form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary"  onClick={onClose}>Close</Button>
        <Button variant="primary" onClick={updateService}>
        Update
      </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditService;
