import React,{useState,useEffect} from "react";
import Search from "../../components/home/search/search";
import "./artists.css";
import axios from "axios";
import { baseUrl } from "../../utils/BaseUrl";
import AlertMessage from "../../components/alerts";
import { set } from "react-hook-form";
import moment from "moment";

export default function Partists() {
  const a = "";
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [artist,setArtist]=useState([]);
  const [payableAmount, setPayableAmount] = useState("");
  const [showMakePaymentForm, setShowMakePaymentForm] = useState(false);
  const [artistpayment,setArtistpayment]=useState();
  const [paymentStatus, setPaymentStatus] = useState("");
  function SetHeader() {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
}
const handleSendClick = async () => {
  try {
    const response = await axios.post(`${baseUrl}/api/payments/paid`, {
      id: selectedArtist.withdraw_id,
      });
    if (response.data.status === "success") {
      setResponse(prevResponse =>
        prevResponse.map(item =>
          item.artist.id === selectedArtist.id ? { ...item, status: "completed" } : item
        )
      );
    }
  } catch (error) {
    console.error("Error updating payment status:", error);
    // Handle error
  }
};
useEffect(() => {
    SetHeader()
    axios.get(`${baseUrl}/api/payments/withdraws`).then(res => {
        if (res.errors?.length) {
            AlertMessage("error", 'Unable to fetch data')
        }
        else {
            setResponse(res.data.records)
            const func=res.data.records.map((item)=>{
              return item.artist;
            })
            setLoading(false)
        }
        if (paymentStatus === "completed" && selectedArtist) {
          handleSendClick();
        }
    })
}, [paymentStatus])
const handlePayClick = (artist) => {
  setSelectedArtist(artist);
  setArtistpayment("");
  setPayableAmount("");
  // Check if artist.amount is valid and convert to a number
  const artistPaymentAmount = parseFloat(artist.amount);
  setArtistpayment(artistPaymentAmount);
  if (isNaN(artistPaymentAmount)) {
    console.log("Invalid artist amount");
    return;
  }
  if (artistPaymentAmount <= 0) {
    console.log("Invalid artist amount");
    return;
  }
  const commissionPercentage = 0.20; // 20% commission
  const commissionAmount =  commissionPercentage*artistPaymentAmount ;
  const totalPayableAmount = artistPaymentAmount - commissionAmount;

  // Convert totalPayableAmount to a string with 2 decimal places
  const payableAmountString = totalPayableAmount.toFixed(2);

  setPayableAmount(payableAmountString);
  setShowMakePaymentForm(true);
};


  return (
    <div className="partists mt-3">
      <Search />
      <span style={{ color: "#7A7A7A" }} className="pl-1">
        Payments {">"} Artists{" "}
      </span>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-8 artist-payment-div">
            {/* artists table section */}
            <div className="card card-outline-secondary">
              {/* heading */}
              <div className="card-header">
                <h3 className=" mb-0 ">Artist Payments</h3>
              </div>
              {/* body */}
              <div>
                {/* table for artist payment */}
                <table className="table table-striped">
                  <thead>
                    {/* table list */}
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Contact no</th>
                      <th>Amount</th>
                      <th>Card Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* table body item1 start */}
                    
                    {response?.map((item, key) => (
                      
                    <tr>
                      <th scope="row">{key+1}</th>
                      <td>
                        <div className="artist-img">
                          <img src={`https://artist.nail2u.net/${item?.artist?.image_url}`}  alt="" /> 
                          <span>{item?.artist?.username}</span>
                        </div>
                      </td>
                      <td>{item?.artist?.phone_no}</td>
                      <td>${item?.amount}</td>

                      <td>2222 4053 4324 8877</td>
                      <td>
                        {item.status === "pending" ? (
                          <button className="btn btn-sm bg-dark text-white" onClick={() => handlePayClick({...item.artist, amount: item.amount,withdraw_id:item})}>
                            Pay
                          </button>
                        ) : item.status === "canceled" ? (
                          <button className="btn btn-sm btn-danger">Cancelled</button>
                        ) : item.status === "completed" ? (
                          <button className="btn btn-sm btn-success">Completed</button>
                        ) : null}
                      </td>
                    </tr>
                    ))}



                    {/* table body item1 end */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-4 make-payment-div">
            {/* make payment div */}
            <div className="card card-outline-secondary">
              {/* heading */}
              <div className="card-header">
                <h3 className=" mb-0 ">Make Payments</h3>
              </div>
              {/* body */}
              <div className="p-3 make-payment-body">
                <div>
                  Date: <span className="pl-2">{moment(new Date().toLocaleDateString()).format("MMM DD, YYYY")}</span>{" "}
                </div>
                <div className="pt-3">
                  current balance :{" "}
                  <span className="float-right font-weight-bold">$5000</span>
                </div>
                {/* form */}
                <form className="mt-5">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputsender">Sender Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputsender"
                        value="Admin"
                        placeholder="Admin"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputreciever">Reciever Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputreciever"
                        value={selectedArtist && selectedArtist.username ? selectedArtist.username : ''}
                        placeholder="Shailny Cordon"
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputSaccountno">Sender Account no</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputSaccountno"
                        placeholder="05451454512"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputRaccountno">
                        Reciever account No
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputRaccountNo"
                        placeholder="54546546"
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-11">
                      <label htmlFor="payableAmount">Amount</label>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control "
                          value={artistpayment}
                          id="PayableAmount"
                        />
                        <p className="pl-3"> commission:12%</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label htmlFor="payableAmount">Payable Amount</label>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control "
                          id="PayableAmount"
                          value={payableAmount?payableAmount:''}
                        />
                        
                        {selectedArtist ? (
                                    <button className="btn ml-4" onClick={handleSendClick}>
                                      Send
                                    </button>
                                ) :
                                <button className="btn ml-4 " disabled >Send</button>
                                }
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
