import React, { useEffect, useState } from "react";
import "./artistlist.css";
import Search from "../../home/search/search";
import Pagination from "../customers/Pagination";
import { useDispatch, useSelector } from "react-redux";
import ArtistCard from "../../system-component/artistCard";
import SpinnerLoader from "../../loaders/Spiner";
import axios from "axios";
import { baseUrl } from "../../../utils/BaseUrl";
import AlertMessage from "../../alerts";


const getData = (data) => {
    let dateInstance = new Date(data);
    return dateInstance.getFullYear();
}

export default function ArtistsList() {

    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const [response, setResponse] = useState([]);
    const [loading, setloading] = useState(false)
    const [dependency, setDependency] = useState(0)
    function SetHeader() {
        const token = localStorage.getItem('token')
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    SetHeader()
    useEffect(() => {
        setloading(true)
        axios.post(`${baseUrl}/api/artist/list-all`)
            .then(res => {
                if (res.data.errors.length) {
                    AlertMessage('error', "Unable To Fetch Data")
                }
                else {
                    setResponse(res.data.records)
                    setloading(false)
                }
            })
    }, [dependency]);



    return (
        <div>
            <div className="customerDetails">
                <Search />
                <span style={{ color: "#7A7A7A" }} className="pl-1">
                    users/artists
                </span>
                <div className="card card-outline-secondary mt-2  artist__detail_pro">
                    <div
                        className="card-header"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <h3 className=" mb-0 ">List Of All artist</h3>
                        <button className="btn-add-artist">
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            <span>Add new Artist</span>
                        </button>
                    </div>
                    <div className="artist-card-body pl-4">
                        {

                            !loading ? response.map((artist, key) => (
                                <ArtistCard
                                    artistId={artist.id}
                                    phoneNo={artist.phone_no}
                                    bookingsCount={artist.bookings_count}
                                    username={artist.username}
                                    profileImage={artist.image_url}
                                    coverImage={artist.cover_image}
                                    workingSince={getData(artist.created_at)}
                                    rating={artist.avg_rating}
                                    dependency={dependency}
                                    setDependency={setDependency}
                                />
                            )
                            )
                                :
                                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '50%' }}>  <SpinnerLoader /></div>


                        }
                    </div>
                    <Pagination />
                </div>
            </div>
        </div>
    );
}

