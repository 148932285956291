import React, { useState } from 'react';
import { Container, } from "react-bootstrap";
import SpinnerLoader from "../../components/loaders/Spiner";
import './login.css';
import { useHistory } from "react-router-dom";
import AlertMessage from '../../components/alerts';
import axios from 'axios';
import { baseUrl } from '../../utils/BaseUrl'
export default function Login() {

    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const history = useHistory();


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) {
            AlertMessage("error", "Email should not be empty");
            return;
        }
        if (!password) {
            AlertMessage("error", "Password should not be empty");
            return;
        }
        const body = {
            email,
            password
        }
        setSubmitted(true);
        axios.post(`${baseUrl}/api/auth/login`, body)
            .then(res => {
                console.log(res.data.records)
                if (res.data.errors.length) {
                    AlertMessage('error', res.data.errors);
                    setSubmitted(false);
                    setEmail('')
                    setPassword('')
                }
                else {
                    history.push("/dashboard");
                    setSubmitted(false);
                    setEmail('')
                    setPassword('')
                    localStorage.setItem('setting', JSON.stringify(res.data.records.setting))
                    localStorage.setItem('user', JSON.stringify(res.data.records.user))
                    localStorage.setItem('token', res.data.records.token)
                    AlertMessage("success", res.data._metadata.message)
                }

            })
    }
    return (<Container className="center-container">
        <form className="loginForm">
            <center class="mt-5">
                <div className='col-md-12 mx-auto formContent'>
                    <form>
                        <div className="form-group dPadding">
                            <label style={{ fontWeight: "bolder" }}>Email address</label>
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                name="email"
                                value={email}
                                className="form-control"
                                placeholder="Enter email" />
                        </div>
                        <div className="form-group">
                            <label style={{ fontWeight: "bolder" }}>Password</label>
                            <input
                                onChange={(e) => setPassword(e.target.value)} type="password"
                                name="password" value={password} className="form-control"
                                placeholder="Password" />
                        </div>
                        <div className="form-group">
                            <button onClick={handleSubmit} type="button" className="btn btn-login">
                                {submitted ? <SpinnerLoader /> : "Login"}
                            </button>
                        </div>
                    </form>
                </div>
            </center>
        </form>
    </Container>);

};