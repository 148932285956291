import "./recentActivity.css";
import React, { useEffect } from "react";
import { useState } from "react";
import caret from "../recentActivity/CaretLeft.svg";
import caretd from "../recentActivity/CaretOpen.svg";
import { UserInfoDetail } from "../../UserInfoDetail";
import { UserSecondInfo } from "../../UserSecondInfo.jsx";
import axios from "axios";
import { baseUrl } from "../../../utils/BaseUrl";
import AlertMessage from "../../../components/alerts";
import moment from "moment";
import SpinnerLoader from "../../loaders/Spiner";
import ArrowBendDownRight from './ArrowBendDownRight.png';
import UserBookingStatus from "./userBookingStatus";
export default function Users() {
  const [onRoute, setOnRoute] = useState(false);
  const [onGoing, setOnGoing] = useState(false);

  const idone = 1;
  const idthree = 3;
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (key) => {
    setActiveAccordion(activeAccordion === key ? null : key);
  };
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true)
  const [dependecy, setDependency] = useState(0)

  useEffect(() => {
    setLoading(true)
    axios.get(`${baseUrl}/api/dashboard/get-recent-activity`)
      .then(res => {
        if (res.errors?.length) {
          AlertMessage("error", 'Unable to fetch data')
        }
        else {
          console.log(res.data.records);
          setResponse(res.data.records)
          setLoading(false)
        }
      })
  }, [dependecy, setDependency])

  return (
    <div>
      {/* Recent activity section start */}
      <div className="container-fluid mt-4 recent-activity">
        <div className="row">
          <div className="col-12">
            <div className="recent-activity-head">
              <span>Recent Activity</span>
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </div>
            {/* table for recent activity*/}
            <table className="table table-striped">
              <thead>
                {/* table list */}
                      <tr>
                        <th>Order No</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Services</th>
                        <th>Customer</th>
                        <th>Location</th>
                        <th>Amount</th>
                        <th>Artist'sName</th>
                        <th>Status</th>
                      </tr> 
              </thead>
              <tbody>
        {!loading ? (
          response?.map((activity, key) => (
            <React.Fragment key={key}>
              <tr onClick={() => toggleAccordion(key)}>
                <th scope="row">
                <span className={`order-no ${activeAccordion === key ? 'active' : ''}`}>
          {key + 1} <span className="accordion-arrow">{activeAccordion === key ? '\u2303' : '\u2304'}</span>
              </span>


                </th>
                <td>04/08/2023</td>
                <td>01:00PM</td>
                <td>service</td>
                <td>{activity.client && activity.client.username ? activity.client.username : ''}</td>
                <td>{activity.client && activity.client.address ? activity.client.address : ''}</td>
                <td>{activity.total_price ? activity.total_price : ''}</td>
                <td>{activity.artist && activity.artist.username ? activity.artist.username : ''}</td>
                <td>
                  {/* <button className="btn-route-status">Onroute</button> */}
                  <UserBookingStatus status={activity?.booking_location?.status ? activity?.booking_location.status : ''}/>
                </td>
              </tr>
              {activeAccordion === key && (
                <tr>
                  <td colSpan={9}>
                    <div className="accordion-content">
                        <div className="col-12">
              
              {/* body */}
              {activity.booking_location.status==='start' ? (
              <div>
             
                <div className="artist-timeline-table">
                  {/* google map */}
                  <div className="col-3 mt-4">
                    <iframe
                      title="customer-location"
                      width="250"
                      height="250"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      src={`https://www.google.com/maps/embed/v1/directions?origin=${activity?.booking_location?.artist_latitude},${activity?.booking_location?.artist_longitude}&destination=${activity?.booking_location?.user_latitude},${activity?.booking_location?.user_longitude}&key=AIzaSyB1ATljOQdQSbKf_-icbQbfQqBqZlmwD0I`}
                      >
                      <a href="http://www.gps.ie/">sport gps</a>
                    </iframe>
                  </div>
                  {/* table */}
                  <div className="col-9" style={{marginTop:'auto'}}>
                    <table className="table artist-table">
                      <thead>
                        <th>Amount</th>
                        <th>Booking Number</th>
                        <th>Service Type</th>
                        <th>Distance</th>
                        <th>Payment Method</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>${activity?.total_price}</td>
                          <td>{activity?.id}</td>
                          <td> 
        {activity.booking_service.map(service => (
        <div key={service.id}>{service.name}</div>))}
                          </td>
                          <td>N/A</td>
                          <td>Clover</td>
                        </tr>
                      </tbody>
                    </table>
                    {/* timeline  start*/}
                    <div className="timeline-artist-location">
                      <div>
                        <i className="fa fa-map-marker" aria-hidden="true" style={{marginLeft:"15px"}}></i>
                        <h5>Customer’s Location</h5>
                        <p>{activity?.client?.address}</p>
                      </div>
                      <div>
                        <h5>Time Left</h5>
                        <p>N/A</p>
                      </div>
                      <div>
                        <h5>Artist’s Location </h5>
                        <p>{activity?.artist?.street_name},{activity?.artist?.city},{activity?.artist?.state},{activity?.artist?.zipcode}</p>
                        
                        </div>
                    </div>
                    {/* timeline  end*/}
                  </div>
                </div>
              </div>
              ) : (
                <div>
             
                {/* <div className="artist-timeline-table"> */}
<div style={{ display: 'inline-flex', alignItems: 'flex-start' }}>
                  {/* google map */}
                  <div className="col-3 mt-4">
                  <img src={ArrowBendDownRight} alt="map"/>
                  </div>
                  {/* table */}
                  <div className="col-12" style={{marginTop:'auto'}}>
                    <table className="table artist-table">
                      <thead>
                        <th>Amount</th>
                        <th>Booking Number</th>
                        <th>Service Type</th>
                        <th>Distance</th>
                        <th>Payment Method</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>${activity?.total_price}</td>
                          <td>{activity?.id}</td>
                          <td> 
                            {
                            activity.booking_service.map(service => (
                            <div key={service.id}>{service.name}</div>)
                            )}
                          </td>
                          <td>N/A</td>
                          <td>Clover</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              )}
          
          
          
            </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={9}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <SpinnerLoader />
              </div>
            </td>
          </tr>
        )}
      </tbody>
            </table>

            {/* table end */}
          </div>
        </div>
      </div>
      {/* Recent activity section end */}
    </div>
  );
}
