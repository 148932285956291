import React from "react";
import apic from "../services/dealimg.png";
import { green } from "@material-ui/core/colors";
import PublishButton from "./components/PublishButton";
import { useState } from "react";
import EditDeal from "./components/EditDeal";
import './components/EditDeal.css';
import axios from "axios";
import { baseUrl } from "../../utils/BaseUrl";
import AlertMessage from "../../components/alerts";
export default function DealOfferSlide(props) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState(null);

  const handlePencilClick = (dealId) => {
    setSelectedDealId(dealId);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setSelectedDealId(null);
    setShowEditModal(false);
  };
  const deleteDeal = (id) => {
    const formData = new FormData();
    formData.append('id', id);
    axios.post(`${baseUrl}/api/deal/delete`, formData)
      .then(res => {
        if (res.data.errors?.length) {
          AlertMessage('error', 'Unable to delete');
        } else {
          AlertMessage('success', res.data._metadata.message);
          window.location.reload();
        }
      });
  }  
  return (
    
    <div className="card ongoing-deal m-2">
      <img src={apic} alt="Card" />
      <span className="circle-div-right">
        <i className="fa fa-trash-o " style={{cursor:"pointer"}} onClick={()=>deleteDeal(props.deal.id)} aria-hidden="true"></i>
      </span>
      <span className="circle-div-left">
        <i
          className="fa fa-pencil"
          aria-hidden="true" style={{cursor:"pointer"}}
          onClick={() => handlePencilClick(props.deal.id)}
        ></i>
      </span>

      {/* ... Other content ... */}
      
      {/* Render the EditDeal component */}
      <EditDeal
        showEditModal={showEditModal}
        handleEditModalClose={handleEditModalClose}
        selectedDealId={selectedDealId}
      />

      {/* <p className="bg-dark label pl-2">Order no : 23221</p> */}
      <p className="heading">{props.deal.name}</p>
      <p className="sub-heading pt-1">Service</p>
      {props.deal.services && props.deal.services.length > 0 ? (
        props.deal.services.map((service, index) => (
          <p className="normal" key={index}>
            <b>{service.name},</b>
          </p>
        ))
      ) : (
        <p className="normal">No services available</p>
      )}
      <span className="pt-1">
        <span className="sub-heading ">Originalprice :</span>
        <span className="float-right pr-2">${props.deal.totalAmount !== null ? props.deal.totalAmount : '0'}</span>
      </span>
      <span className="pt-1">
        <span className="sub-heading">Discount : </span>
        <span className="float-right pr-2">{props.deal.discount_percentage}%</span>
      </span>
      <span className="pt-1">
        <span className="sub-heading">Discounted price : </span>
        <span className="float-right pr-2">${props.deal.calculated_discount !== null ? props.deal.calculated_discount : '0'}</span>
      </span>
      <span className="pt-1">
        <span className="sub-heading">Duration : </span>
        <span className="float-right pr-2">{props.deal.start_date} - {props.deal.end_date}</span>
      </span>
      <PublishButton deal={props}/>
    </div>
     
  );
}
