import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const LocationMap = ({ google, locations }) => {
  const [initialCenter, setInitialCenter] = useState({
    lat: locations.length > 0 ? locations[0].artistLatitude : 0,
    lng: locations.length > 0 ? locations[0].artistLongitude : 0,
  });
  useEffect(() => {
    if (locations.length > 0) {
      setInitialCenter({
        lat: locations[0].artistLatitude,
        lng: locations[0].artistLongitude,
      });
    }
  }, [locations]);

  return (
    <Map
      google={google}
      zoom={12}
      style={{ width: '100%', height: '600px' }}
      initialCenter={initialCenter}
    >
      {locations.map((location, index) => (
        <Marker
          key={index}
          position={{ lat: location.artistLatitude, lng: location.artistLongitude }}
          icon={{
            url: `https://artist.nail2u.net/${location.image_url}`,
            scaledSize: new window.google.maps.Size(40, 40),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(20, 20),
          }}
        />
      ))}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyB1ATljOQdQSbKf_-icbQbfQqBqZlmwD0I',
})(LocationMap);
