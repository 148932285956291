import "./dailydeal.css";
import Search from "../../components/home/search/search";
import { Link } from "react-router-dom";
import DealOffer from "../../components/services/DealOffer";
import apic from "../services/girl.png";
import DealOfferSlide from "../../components/services/DealOfferSlide";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/BaseUrl";
import AlertMessage from "../../components/alerts";
import Pagination from "./Pagination";
import { useEffect } from "react";
import SpinnerLoader from "../../components/loaders/Spiner";
import AllPagination from './AllPagination'
import Dropdown from './Components/Dropdown'
export default function DailyDeal() {

  const [selectedServices, setSelectedServices] = useState([]);
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [discount, setDiscount] = useState()
  const [name, setName] = useState()
  const [image, setImage] = useState()
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(0)
  const [current, setCurrent] = useState(1)
  const [last, setLast] = useState(0)
  const [list, setList] = useState()
  const [loader, setLoader] = useState(false)
  const [listAll, setListAll] = useState()
  const [allLoading, setAllLoading] = useState(false)
  function SetHeader() {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  }
  SetHeader();
  const AddDeal = (selectedServicesIds, calculatedDiscountAmount,totalAmount) => {

    let data = new FormData();
    data.append('name', name)
    data.append('start_date', startDate)
    data.append(' end_date', endDate)
    data.append(' image_url', image)
    data.append(' discount', discount)
    data.append('calculated_discount', calculatedDiscountAmount);
    data.append('totalAmount', totalAmount);
    selectedServicesIds.forEach(serviceId => {
      data.append('selected_services[]', serviceId);
    });
    
    setLoading(true)

    axios.post(`${baseUrl}/api/deal/add-new`, data)
      .then(res => {
        console.log(res)
        if (res.data.errors?.length) {
          AlertMessage('error', "Unable To Add Deal")
          setLoading(false)
        }
        else {
          AlertMessage('success', "Deal Added Successfully")
          setLoading(false)
          setDiscount()
          setEndDate()
          setStartDate()
          setImage()
        }
      })
  }
  const handleAllDeal = () => {
    const selectedServiceIds = selectedServices.map(service => service.id);
    const totalAmount = selectedServices.reduce((total, service) => total + parseFloat(service.amount), 0);
    const calculatedDiscountAmount = discount
    ? (
        selectedServices.reduce((total, service) => total + parseFloat(service.amount), 0) *
        (1 - discount / 100)
      ).toFixed(2)
    : 0.00;

    AddDeal(selectedServiceIds,calculatedDiscountAmount,totalAmount);
  };

  useEffect(() => {
    setLoader(true)
    axios.get(`${baseUrl}/api/deal/list-ongoing?page=${currentPage}`)
      .then(res => {
        console.log(res)
        if (res.data.errors?.length) {
          AlertMessage('error', "Unable to Fetch On-going deals")
          setLoader(false)
        }
        else {
          setList(res.data.records.data)
          setCurrentPage(res.data.records.current_page)
          setLastPage(res.data.records.last_page)
          setLoader(false)
        }
      });


  }, [currentPage])

  useEffect(() => {
    setAllLoading(true)
    axios.get(`${baseUrl}/api/deal/list-all?page=${current}`)
      .then(res => {
        console.log(res)
        if (res.data.errors?.length) {
          AlertMessage('error', "Unable to Fetch On-going deals")
          setAllLoading(false)
        }
        else {
          setListAll(res.data.records.data)
          setCurrent(res.data.records.current_page)
          setLast(res.data.records.last_page)
          setAllLoading(false)
        }
      });
  }, [current])
  return (
    <div className="dailydeal">
      <Search />
      <span style={{ color: "#7A7A7A" }} className="pl-1">
        Services/Deals
      </span>
      <div className="container-fluid pt-3 deal-container">
        <div className="row">
          <div className="col-12">
            {/* template */}
            <div className="customerDetails">
              {/* card */}
              <div className="card card-outline-secondary "
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                overflowX: 'auto',
                width: '1600px', 
                border: '1px solid #ccc', 
                padding: '10px', 
                borderRadius: '5px'

              }}
              >
                {/* heading */}
                <div className="card-header">
                  <h3 className=" mb-0 ">Ongoing deal</h3>
                </div>
                {/* body */}
                <div style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  overflowX: 'auto',
                  

                }} className=" pt-3 pb-3">
                  {/* card for ongoing deals start */}
                  {loader ? <div style={{
                    display: 'flex', margin: '0 auto', height: '280px', alignItems: 'center'
                  }}><SpinnerLoader /></div> : list?.map(item => (
                    <DealOffer deal={item} />
                  ))}
                  {/* card for ongoing deals end */}
                </div>
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} lastPage={lastPage} />
              </div>
            </div>
          </div>
          </div>
          </div>
          <div className="container-fluid pt-3 deal-container">
        <div className="row">
          <div className="col-12">
            {/* template */}

            {/* card */}
            <div className="card card-outline-secondary">
              {/* heading */}

              {/* body */}
              <div>
                {/* add new deal and services */}
                <div className="row">
                  <div className="col-6 add_new_deals">
                    <div className="card-header bg-white">
                      <h4 className=" mb-0 ">add new deals</h4>
                    </div>
                    <div className="pl-4 pt-3">
                      {/* form from add new deal */}
                      <form className="select__services">
                      <div className="form-group col-md-12 pl-3">
                            <label htmlFor="name">Deal Name</label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control "
                                id="dealName"
                                onChange={e => { setName(e.target.value) }}
                              />
                             
                            </div>
                          </div>
                        <Dropdown setSelectedServices={setSelectedServices}/>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="inputEmail4">Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              id="inputPassword4"
                              placeholder="04/08/2021"
                              onChange={(e) => { setStartDate(e.target.value) }}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="inputPassword4">End date</label>
                            <input
                              type="date"
                              className="form-control"
                              id="inputPassword4"
                              placeholder="04/08/2021"
                              onChange={(e) => { setEndDate(e.target.value) }}

                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="inputCity">Add Image</label>

                            <div className="image-upload">
                              {" "}
                              <label htmlFor="file_upload">
                                {" "}
                                <img src="" alt="" className="uploaded-image" />
                                <div className="h-100">
                                  <div className="dplay-tbl">
                                    <div className="dplay-tbl-cell">
                                      {" "}
                                      <i
                                        className="fa fa-camera"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <input
                                  data-required="image"
                                  type="file"
                                  name="image_name"
                                  id="file_upload"
                                  className="image-input"
                                  data-traget-resolution="image_resolution"
                                  onChange={(e) => { setImage(e.target.files[0]) }}
                                />
                              </label>{" "}
                            </div>
                          </div>

                          <div className="form-group col-md-4 pl-3">
                            <label htmlFor="inputZip">Discount</label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <input
                                type="number"
                                className="form-control "
                                id="inputZip"
                                onChange={e => { setDiscount(e.target.value) }}
                              />
                              <span className="pl-3 font-weight-bold">%</span>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card mt-3 mr-3">
                      <div className="deal-services pl-2 pt-2 ">
                        <img src={apic} alt="" />
                        <div>
                          <h6>services</h6>
                            <div className="deal-services-info">
                            {selectedServices.length > 0 ? (
                              selectedServices.map((service, index) => (
                                <div key={index}>
                                  <p style={{fontWeight:'900',color:'black'}}>{service.name}</p>
                                  <p className="pl-5" style={{color:'red'}}>{service.amount}</p>
                                </div>
                              ))
                            ) : (
                              <p>Please select services.</p>
                            )}

                          </div>
                                          </div>
                      </div>
                      {/* recipt total deal price */}
                      <div className="deal-services-total pl-4 pr-4">
                        <div>
                          <p>Total</p>
                          <p>Discount</p>
                          <p className="font-weight-bold text-dark">
                            Discounted Price
                          </p>
                          <p>Start Date</p>
                          <p>End Date</p>
                        </div>
                        <div>
                        <p>${selectedServices.reduce((total, service) => total + parseFloat(service.amount), 0)}</p>
                        <p>{discount ? `${discount}%` : "0%"}</p>
                          <p className="font-weight-bold text-dark">
                          ${discount
                                      ? (
                                          selectedServices.reduce((total, service) => total + parseFloat(service.amount), 0) *
                                          (1 - discount / 100)
                                        ).toFixed(2)
                                      : "0.00"}

                          </p>
                          {startDate ? <p>{startDate}</p> : <p style={{ color: "black" }}>Not Selected</p>}
                          {endDate ? <p>{endDate}</p> : <p style={{ color: "black" }}>Not Selected</p>}
                        </div>
                      </div>
                    </div>
                    <button disabled={loading} onClick={handleAllDeal} className="btn btn-dark mt-2 mb-2">
                      Make a new Deal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Deal pakages start  */}

      {/* template */}
      <div className="customerDetails pt-3 ">
        {/* card */}
        <div className="card card-outline-secondary">
          {/* heading */}
          <div className="card-header">
            <h3 className=" mb-0 ">Deal Packages</h3>
          </div>
          {/* body */}

          <div className="card card-outline-secondary "
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                overflowX: 'auto',
                width: '1600px', 
                border: '1px solid #ccc', 
                padding: '10px', 
                borderRadius: '5px'

              }}>
          <div style={{
                  display: 'flex',
                  flexWrap: 'nowrap',

                  overflowX:'auto'
                  

                }} className=" pt-3 pb-3">
                
                        {allLoading ? <div style={{
                          display: 'flex', margin: '0 auto', height: '280px', alignItems: 'normal',
                        }}><SpinnerLoader /></div> : listAll?.map(item => (
                          <DealOfferSlide deal={item} />
                        ))}
                    
               </div>
              
            {/* carousel data end here */}
            <AllPagination current={current} setCurrent={setCurrent} last={last} />
            </div>
          </div>
        </div>
      </div>
    
  );
}
