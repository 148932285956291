import React, { useEffect, useState } from "react";
import "./artistProfile.css";
import Search from "../../components/home/search/search";
import artistprofile from "../users/artistprofile.png";
import rated from "../users/rated.png";
import unrated from "../users/unrated.png";
// import reviewpic from "../users/reviewpic.png";
import PortfolioItem from "../../components/users/artists/PortfolioItem";
import { useDispatch, useSelector } from "react-redux";
import { getSingleArtist } from "../../redux/action/artists";
import SpinnerLoader from "../../components/loaders/Spiner";
import axios from "axios";
import { baseUrl } from "../../utils/BaseUrl";
import { useParams } from "react-router-dom";
import AlertMessage from "../../components/alerts";
import UserBookingStatus from "./userBookingStatus";
import moment from "moment";
import { set } from "react-hook-form";
export default function ArtistProfile() {

    const params = useParams()
    const id = params.id;
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const [singleArtistLoader, setSingleArtistLoader] = useState(false);
    const [singleArtistData, setSingleArtistData] = useState('');
    const [artistServices, setartistServices] = useState([]);
    const [artistReview, setartistReview] = useState([]);
    const [artistJobDonCount, setartistJobDonCount] = useState([]);
    const [artistJobDone, setartistJobDone] = useState([]);
    const [singleBooking, setsingleBooking] = useState([]);
    const [singleBookingServices, setsingleBookingServices] = useState([]);
    const [singleBookingClient, setsingleBookingClient] = useState([]);
    const [singleBookingArtist, setsingleBookingArtist] = useState([]);
    const [singleBookingLocation, setsingleBookingLocation] = useState([]);
    const [artistPortfolio, setartistPortfolio] = useState([]);
    const [slots, setSlots] = useState([]);
    const [onRoute,setOnRoute]=useState([]);
    const [currentWeekdays, setCurrentWeekdays] = useState([]);
    const [currentDate, setCurrentDate] = useState(moment());
    const timeSlots = ['9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM'];   
    const googleMapurl=`https://www.google.com/maps/embed/v1/directions?origin=${onRoute?.artist_latitude},${onRoute?.artist_longitude}&destination=${onRoute?.user_latitude},${onRoute?.user_longitude}&key=AIzaSyB1ATljOQdQSbKf_-icbQbfQqBqZlmwD0I`;
    const [errorHandler, setErrorHandler] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const handlePrevMonth = () => {
        setSelectedDay(null);
        setCurrentDate((prevDate) => prevDate.clone().subtract(1, 'month'));
      };
      
      const handleNextMonth = () => {
        setSelectedDay(null);
        setCurrentDate((prevDate) => prevDate.clone().add(1, 'month'));
      }; 
      useEffect(() => {
        const firstDayOfMonth = currentDate.date(1).day();
        const weekdays = moment.weekdaysShort();
        const weekdaysStartingFromFirstDay = [
            ...weekdays.slice(firstDayOfMonth),
            ...weekdays.slice(0, firstDayOfMonth)
        ];
        setCurrentWeekdays(weekdaysStartingFromFirstDay);
      }, [currentDate]);


    const getArtistById = (id) => {
        setSingleArtistLoader(false);
        dispatch(getSingleArtist({ id: id })).then((res) => {
            setSingleArtistData(res.data.records)
            setSingleArtistLoader(true)
        })
    };

    const handleDayClick = (day) => {
        const selectedDate = currentDate.clone().date(day);
        const formattedDate = selectedDate.format('DD-MM-YYYY');
        setSelectedDay(day);
        axios.post(`${baseUrl}/api/booking/get-slots`, {  id:id, date: formattedDate }) 
          .then((res) => {
            if (res.data.errors?.length) {
              console.error('Unable to load data');
            } else {
                if(res.data.records.length===undefined){
                    setErrorHandler(true)
                }else {
                setErrorHandler(false)
                setSlots(res.data.records?.map((item) => item.scheduler.time));}
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      };
      const daysInMonth = currentDate.daysInMonth();
      const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);


      
    function SetHeader() {
        const token = localStorage.getItem('token')
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    useEffect(() => {
        SetHeader();
        setSingleArtistLoader(true)
        axios.post(`${baseUrl}/api/user/get-user-detail`, { id })
            .then(res => {
                if (res.data.errors?.length) {
                    AlertMessage('error', "Unbale to load data")

                }
                else {
                    console.log(res.data.records);
                    setartistPortfolio(res.data.records.portfolio)
                    setSingleArtistData(res.data.records.user)
                    setartistServices(res.data.records.user.artist_services);
                    setartistReview(res.data.records.review);
                    setartistJobDonCount(res.data.records.job_done_count);
                    setartistJobDone(res.data.records.job_done);
                    setOnRoute(res.data.records.onRoute);
                    if(
                        res.data &&
                        res.data.records &&
                        res.data.records.booking
                      ){
                        setsingleBooking(res.data.records.booking);
                      }
                    
                    if(
                        res.data &&
                        res.data.records &&
                        res.data.records.booking &&
                        res.data.records.booking.booking_service
                      ){
                        setsingleBookingServices(res.data.records.booking.booking_service);
                      }

                      if(
                        res.data &&
                        res.data.records &&
                        res.data.records.booking &&
                        res.data.records.booking.client
                       
                      ){
                        setsingleBookingClient(res.data.records.booking.client);
                      }

                      if(
                        res.data &&
                        res.data.records &&
                        res.data.records.booking &&
                        res.data.records.booking.artist
                       
                      ){
                        setsingleBookingArtist(res.data.records.booking.artist);
                      }

                      if(
                        res.data &&
                        res.data.records &&
                        res.data.records.booking &&
                        res.data.records.booking.booking_location
                       
                      ){
                        setsingleBookingLocation(res.data.records.booking.booking_location);
                      }

                      if(
                        res.data &&
                        res.data.records &&
                        res.data.records.portfolio
                       
                      ){
                        setartistPortfolio(res.data.records.portfolio);
                      }
                    setSingleArtistLoader(false)
                }
            })
            .catch(err => {
                console.log(err);
                AlertMessage('error', "Unbale to load data")
            }
            )  
     }, []);
 
 return (<div className="customers">
        {singleArtistLoader}
        <Search />
        <span style={{ color: "#7A7A7A" }} className="pl-1">
            users/artists/artist Details
        </span>
        {!singleArtistLoader ? <div className="card card-outline-secondary">
            <div className="p-3">
                <div className="row">
                    <div className="col-5 pt-6 artist-profile-detail-div">
                        <div className="row artist-image-top">
                                <div className="col-4 pt-6 ">
                                    <img className="img" src={`https://artist.nail2u.net/${singleArtistData.image_url}`} alt="" />
                                </div>
                                <div className="col-8 pt-6">
                                    <div className=" pl-1">
                                        <h4>{singleArtistData.username}</h4>
                                        <img className="ratedIcon" src={rated} alt="" />
                                        <img className="ratedIcon" src={rated} alt="" />
                                        <img className="ratedIcon" src={rated} alt="" />
                                        <img className="ratedIcon" src={rated} alt="" />
                                        <img className="ratedIcon" src={unrated} alt="" />
                                        <p className="pt-2">Expert in { artistServices.map((service, key) => (
                                            <span>{ service.name }, </span>  
                                        )) } </p>
                                        <p>
                                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                                            {singleArtistData.phone_no}
                                        </p>
                                        {/* <i className="fa fa-trash-o float-right" aria-hidden="true"></i> */}
                                    </div>
                                </div>
                        </div>
                        
                    </div>
                    <div className="col-7">
                        <div className="card-header bg-white artist-card-header-info">
                            <h6 className=" mb-0 ">Current Status</h6>
                            <UserBookingStatus status={singleBookingLocation?.status ? singleBookingLocation.status : ''}/>
                        </div>
                        <div>
                            <div className="artist-timeline-table">
                                <div className="mt-4">
                                    <iframe
                                        title="artist-location"
                                        width="300"
                                        height="200"
                                        frameBorder="0"
                                        scrolling="no"
                                        marginHeight="0"
                                        marginWidth="0"
                                        src={googleMapurl}
                                    >
                                        <a href="http://www.gps.ie/">sport gps</a>
                                    </iframe>
                                </div>
                                <div>
                                    <table className="table artist-table">
                                        <thead>
                                            <th>Amount</th>
                                            <th>Booking Number</th>
                                            <th>Service Type</th>
                                            <th>Distance</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>${onRoute?.bookings?.total_price ? onRoute?.bookings.total_price : 'N/A'}</td>
                                                <td>{onRoute?.booking_id ? onRoute.booking_id : 'N/A'}</td>
                                                <td>{onRoute?.bookings?.booking_service.map((item) => item.name)}
                                                </td>
                                                <td>N/A</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* timeline  start*/}
                                    <div className="timeline-artist-location">
                                        <div>
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            <h5>Customer’s Location</h5>
                                            {onRoute && onRoute.bookings ?
                                            <p>{onRoute?.bookings?.client?.address},{onRoute?.bookings?.client?.street_name},{onRoute?.bookings?.client?.city},{onRoute?.bookings?.client?.state}</p>
                                        : <p>N/A</p>} 
                                        </div>
                                        <div>
                                            <h5>Time Left</h5>
                                            <p>5 min</p>
                                        </div>
                                        <div>
                                            <h5>Artist’s Location </h5>
                                            {onRoute && onRoute.bookings ?
                                            <p>{onRoute?.bookings?.artist?.street_name},{onRoute?.bookings?.artist?.city},{onRoute?.bookings?.artist?.state}</p>
                                        : <p>N/A</p>}    
                                        
                                            </div>
                                    </div>
                                    {/* timeline  end*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <div className="card card-outline-secondary">
                            <div className="card-header">
                                <h5 className=" mb-0 ">Total Earning</h5>
                            </div>
                            <div>
                                <center className="pt-4">
                                    <h2>
                                    ${singleArtistData.total_balance ? singleArtistData.total_balance : 0} 
                                    </h2>
                                </center>
                            </div>
                        </div>
                        <div className="card card-outline-secondary mt-2">
                            <div className="card-header">
                                <h5 className="">Services</h5>
                                 { artistServices.map((service, key) => (
                                        // <span>{ service.name }, </span> 
                                        <><img className="img service_image" src={`${service.image}`} alt="" /> 
                                        <div className="">
                                            <p className="pt-2"> { service.name } </p>
                                        </div></>
                                    )) } 
                                
                            </div>
                            <div className="artist-profile-services-div p-2">
                                <div>
                                    {/* {singleArtistData?.services.map(service => <p>{service.name}</p>)} */}
                                </div>
                                <div>
                                    {/* {singleArtistData?.services.map(service => <p>${service.price}</p>)} */}
                                </div>
                            </div>
                        </div>
                        <div className="card card-outline-secondary mt-2">
                            <div className="card-header">
                                <h5 className=" mb-0 ">Other details</h5>
                            </div>
                            <div className=" p-2 other__details">
                                <div>
                                    {/* <h5>Joining date</h5>
                                    <p>{singleArtistData.created_at}</p> */}

                                    <h5>Address</h5>
                                    <p>{singleArtistData.state ? singleArtistData.state : 'N/A'}, {singleArtistData.city ? singleArtistData.city : 'N/A'}, {singleArtistData.street_name ? singleArtistData.street_name : 'N/A'}</p>

                                    {/* <h5>Account No</h5>
                                    <p>{singleArtistData.id}</p> */}

                                    <h5>Email ID</h5>
                                    <p>{singleArtistData.email}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="card card-outline-secondary">
                            <div className="card-header">
                                <h5 className=" mb-0 ">available slot details</h5>
                            </div>
                            <div>
                                <div>
                                    <div className="month">
                                        <ul>
                                             <li className="prev" style={{cursor:'pointer'}}onClick={handlePrevMonth}>&#10094;</li>
                                             <li className="next" style={{cursor:'pointer'}}onClick={handleNextMonth}>&#10095;</li> 
                                            <li>
                                            {currentDate.format('MMMM')}
                                            <br />
                                            <span>{currentDate.format('YYYY')}</span>
                                            </li>
                                        </ul>
                                    </div>
                                     <ul className="weekdays">
                                        {currentWeekdays.map((day) => (
                                        <li key={day}>{day}</li>
                                        ))}
                                    </ul>
                                     <ul className="days">
                                    {days.map((day) => (
                                    <li key={day} onClick={() => handleDayClick(day)} 
                                    style={{
                                        cursor: 'pointer',
                                        background: day === selectedDay ? 'black' : 'transparent', 
                                        color: day === selectedDay ? 'white' : 'inherit', 
                                        borderRadius: '5%',
                                      }}
                                    
                                    >
                                        {day}
                                    </li>
                                    ))}
                         </ul>
                                </div>
                                {/* calander end */}

                                <h5 className=" mb-0 pl-3 pt-3">Available slots </h5>
                                <div className="available-slot p-2">
                            {
                            errorHandler ? (
                                timeSlots.map((slot, index) => (
                                    <p key={index}>
                                               {slot}
                                    </p>
                          )))
                          :
                          (
                            timeSlots.map((slot, index) => (
                            <p key={index} className={slots.includes(slot) ? 'active' : ''}>
                            {slot}
                            </p>
                        
                                )))
                            }
                                    

                                </div>
                            </div>
                        </div>

                        {/* customer reviews */}
                        <div className="card card-outline-secondary mt-2">
                            <div className="card-header">
                                <h5 className=" mb-0 ">Customer Reviews</h5>
                            </div>
                            <div className="artist-customer-reviews p-2">
                            { artistReview.map((review, key) => (
                                <div className="row ">
                                    <div className="col-10 customer-review-info">
                                    {review.client && review.client.image_url ? (
                                        <img src={`https://user.nail2u.net/${review.client.image_url}`} alt="profile" />
                                        ) : (
                                        <img src={artistprofile} alt="default-profile" />
                                        )}
                                        
                                        <span>{ review.client && review.client.username ? review.client.username : ""}</span>
                                        <p>{review.review}</p>
                                    </div>
                                    <div className="col-2 customer-review-rating">
                                        <span>{review.rating}</span>
                                        <img src={rated} alt="rating" />
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-3 mr-3 mb-2">
                <div className="container-fluid mt-2  job-post">
                    <div className="row">
                        <div className="col-12">
                            <div className="job-post-head">
                                <div>
                                    {/* <span className="jobs-count">20 </span> */}
                                    <span>Job Done</span>
                                </div>
                                <div>
                                    {" "}
                                    <span>Total Job Done</span>
                                    <span className="jobs-count pl-2">{artistJobDonCount} </span>
                                </div>
                            </div>
                            {/* table for recent activity*/}
                            <table className="table table-striped">
                                <thead>
                                    {/* table list */}
                                    <tr>
                                        <th>No</th>
                                        <th>Order ID</th>
                                        <th>Amount</th>
                                        <th>Client's Name</th>
                                        <th>Location</th>
                                        <th>Date/time</th>
                                        <th>Payment</th>
                                        <th>Commission</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* table body item1 start */}
                                    { artistJobDone.map((jobDone, key) => (
                                    <tr>
                                        <th scope="row">{key + 1}</th>
                                        <td>{jobDone.id}</td>
                                        <td>${jobDone.total_price}</td>
                                        <td>{jobDone.client && jobDone.client?.username ? jobDone.client.username : "-"}</td>
                                        <td>{jobDone.client && jobDone.client?.address ? jobDone.client.address : "-"}</td>
                                        <td>{jobDone.updated_at}</td>
                                        <td className="job-post-btn">
                                            <button className="mr-2 approve">Paid</button>
                                        </td>
                                        <td>12%</td>
                                    </tr>
                                    ))}
                                    {/* table body item1 end */}
                                </tbody>
                            </table>
                            {/* table end */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Portfolio section start */}
            <div className="ml-3 mr-3 mt-2">
                <div className="card card-outline-secondary">
                    {/* heading */}
                    <div className="card-header artist__Protfolio">
                        <h3 className=" mb-0 ">portfolio</h3>
                    </div>
                    {/* body */}
                    <div>
                        {/* carousel data start here */}
                        <div className="container">
                            <div className="row">
                            {artistPortfolio.length > 0 && ( 
                                <div
                                    id="carousel"
                                    className="carousel slide"
                                    data-ride="carousel"
                                >
                                    {/* <ol className="carousel-indicators">
                    <li
                      data-target="#carousel"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li data-target="#carousel" data-slide-to="1"></li>
                  </ol> */}
                                    <div className="carousel-inner">
                                        
                                            <div className="carousel-item active ">
                                                <div className="d-none d-lg-block">
                                                {/* <div className="slide-box ">
                                                    {/* slider item */}
                                                    {/* <PortfolioItem />
                                                    <PortfolioItem />
                                                    <PortfolioItem />
                                                    <PortfolioItem /> */}
                                                {/* </div> */}

                                                <div className="slide-box">
                                                    {/* items box here  start*/}
                                                    { artistPortfolio.map((portfolio, index) => (
                                                        index < 4 &&  <PortfolioItem portfolio={portfolio}/>                             
                                                    )) }
                                                    

                                                    {/* items box end */}
                                                    </div>




                                                </div>
                                            </div>
                                       
                                        {/* second round */}
                                        {artistPortfolio.slice(4).reduce((slideChunks, portfolio, index) => {
                                      if (index % 4 === 0) {
                                        slideChunks.push([portfolio]);
                                          } else {
                                        slideChunks[slideChunks.length - 1].push(portfolio);
                                        }
                                      return slideChunks;
                                    }, []).map((slideItems, slideIndex) => (
                                  <div className="carousel-item" key={slideIndex}>
                                    <div className="slide-box">
                                      {slideItems.map((portfolio, index) => (
                                        <PortfolioItem portfolio={portfolio} key={index} />
                                      ))}
                                    </div>
                                  </div>
                                ))}
                                    </div>
                                    <a
                                        className="carousel-control-prev"
                                        href="#carousel"
                                        role="button"
                                        data-slide="prev"
                                    >
                                        <span
                                            className="carousel-control-prev-icon"
                                            aria-hidden="true"
                                        >
                                            <i
                                                className="fa fa-chevron-left"
                                                aria-hidden="true"
                                            ></i>
                                        </span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a
                                        className="carousel-control-next"
                                        href="#carousel"
                                        role="button"
                                        data-slide="next"
                                    >
                                        <span
                                            className="carousel-control-next-icon"
                                            aria-hidden="true"
                                        >
                                            <i
                                                className="fa fa-chevron-right"
                                                aria-hidden="true"
                                            ></i>
                                        </span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            )}
                            </div>
                        </div>
                        {/* carousel data end here */}
                    </div>
                </div>
            </div>
            {/* portfolio section end */}
        </div> : <SpinnerLoader />}
    </div>);
}
