import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Dropdown from '../../../pages/services/Components/Dropdown';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../../utils/BaseUrl';
import SpinnerLoader from '../../../components/loaders/Spiner';
import moment from 'moment';
import './EditDeal.css';
import { set } from 'react-hook-form';
import AlertMessage from '../../../components/alerts';
const EditDeal = ({ showEditModal, handleEditModalClose,selectedDealId }) => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deal, setDealData] = useState({
    id: selectedDealId,
    services: [],
  });
  const [discount, setDiscount] = useState();
  const [name, setName] = useState('');
 
  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/api/deal/get-details/${selectedDealId}`)
      .then(res => {
        if (res.data) {
          setDealData(res.data.records);
          setLoading(false);
        } else {
          console.error('No service data found');
          setLoading(false);
        }
       
      })
      .catch(error => {
        console.error('Error fetching service data:', error);
        setLoading(false);
      });
  }, [selectedDealId]);
  useEffect(() => {
    const mappedServiceIds = selectedServices.map(service => service.id);
    setDealData(prevDeal => ({
      ...prevDeal,
      services: mappedServiceIds,
      calculated_discount:
        prevDeal.discount_percentage && selectedServices.length > 0
          ? (
              selectedServices.reduce((total, service) => total + parseFloat(service.amount), 0) *
              (1 - prevDeal.discount_percentage / 100)
            ).toFixed(2)
          : "0.00",
          totalAmount: selectedServices.reduce((total, service) => total + parseFloat(service.amount), 0),
    }));
  }, [selectedServices, deal.discount_percentage]);
  const updateDeal=()=>{
    const formData = new FormData();
    formData.append('id', deal.id);
    formData.append('name', deal.name);
    formData.append('discount_percentage', deal.discount_percentage);
    formData.append('start_date', deal.start_date);
    formData.append('end_date', deal.end_date);
    formData.append('image', deal.image);
    deal.services.forEach(serviceId => {
      formData.append('services[]', serviceId);
    });
    formData.append('calculated_discount', deal.calculated_discount);
    formData.append('totalAmount', deal.totalAmount);
    axios.post(`${baseUrl}/api/deal/edit`, formData)
      .then(res => {
        if (res.data.errors?.length) {
          AlertMessage('error', 'Unable to update deal');
        } else {
          AlertMessage('success', res.data._metadata.message);
          handleEditModalClose();
        }
      })
      .catch(error => {
        console.error('Error updating deal:', error);
        AlertMessage('error', 'Unable to update deal');
      });
  }

  return (
    <Modal show={showEditModal} onHide={handleEditModalClose}>
    <Modal.Header closeButton>
      <Modal.Title>Edit Deal</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {/* Form for editing the deal */}
    <div className="edit-deal-container">
      <form className="select__services">
        {/* Deal Name */}
        <div className="form-group">
          <label htmlFor="dealName">Deal Name</label>
          <input
            type="text"
            className="form-control"
            id="dealName"
            value={deal.name}
            onChange={(e) => { 
              setDealData({...deal,name:e.target.value})
            }}
          />
        </div>
  
        {/* Dropdown for selecting services */}
        <Dropdown setSelectedServices={setSelectedServices} />
  
        {/* Start and End Date */}
        <div className="form-group col-md-6">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  value={moment(deal.start_date).format('YYYY-MM-DD')}
                  onChange={(e) => setDealData({...deal,start_date:e.target.value})}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="endDate"
                  value={moment(deal.end_date).format('YYYY-MM-DD')}
                  onChange={(e) => setDealData({...deal,end_date:e.target.value})}
                />
              </div>
       {/* Image upload */}
        <div className="form-group col-md-6">
                <label htmlFor="inputCity">Add Image</label>
                <div className="image-upload">
                  <label htmlFor="file_upload">
                    <img src="" alt="" className="uploaded-image" />
                    <div className="h-100">
                      <div className="dplay-tbl">
                        <div className="dplay-tbl-cell">
                          <i className="fa fa-camera" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <input
              type="file"
              name="image_name"
              id="file_upload"
              className="image-input"
              onChange={(e) => { 
                setDealData({...deal,image:e.target.files[0]})
               }}
            />
              </label>
              </div>
            </div>
              
  
        {/* Discount */}
        <div className="form-group">
          <label htmlFor="discount">Discount</label>
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              id="discount"
              value={deal.discount_percentage}
              onChange={(e) => { 
                setDealData({...deal,discount_percentage:e.target.value})
               }}
            />
            <div className="input-group-append">
              <span className="input-group-text">%</span>
            </div>
          </div>
        </div>
      </form>
      <table className="entered-data-table" style={{ borderCollapse: 'collapse', width: '100%' }}>
  <tbody>
    <tr>
      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Field</th>
      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Value</th>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Deal Name</td>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{deal.name}</td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Selected Services</td>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>

      {selectedServices.length > 0 ? (
                              selectedServices.map((service, index) => (
                                <div key={index}>
                                  <p style={{fontWeight:'900',color:'black'}}>{service.name}</p>
                                  <p className="pl-5" style={{color:'red'}}>{service.amount}</p>
                                   <p className="pl-5" style={{color:'red'}}>{service.id}</p>
                                </div>
                              ))
                            ) : (
                              <p>Please select services.</p>
                            )}
      </td>
 
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Total Price</td>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
      ${selectedServices.reduce((total, service) => total + parseFloat(service.amount), 0)}
      </td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Discount Percentage</td>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
      {deal.discount_percentage ? deal.discount_percentage : "0"}%
      </td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Discount Amount</td>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
      ${deal.discount_percentage
                        ? (
                         selectedServices.reduce((total, service) => total + parseFloat(service.amount), 0) *
                        (1 - deal.discount_percentage / 100)
                            ).toFixed(2)
                                : "0.00"}
        
        </td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Start Date</td>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{deal.start_date}</td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>End Date</td>
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{deal.end_date}</td>
    </tr>
  </tbody>
</table>
{console.log(deal)}
      </div>
    </Modal.Body>
    <Modal.Footer>
    
      <Button variant="secondary" onClick={handleEditModalClose}>
        Close
      </Button>
      <Button variant="primary" onClick={updateDeal}>
      </Button>
    
    </Modal.Footer>
  
  </Modal>
  
  );
};

export default EditDeal;
