import "./jobPost.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../utils/BaseUrl";
import AlertMessage from "../../../components/alerts";
import moment from "moment";
import SpinnerLoader from "../../loaders/Spiner";

export default function JobPost() {

  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true)
  const [dependecy, setDependency] = useState(0)

  useEffect(() => {
    setLoading(true)
    axios.get(`${baseUrl}/api/dashboard/get-job-post-service`)
      .then(res => {
        if (res.errors?.length) {
          AlertMessage("error", 'Unable to fetch data')
        }
        else {
          setResponse(res.data.records)
          setLoading(false)
        }
      })
  }, [dependecy, setDependency])
  return (
    <div>
      {/* Recent activity section start */}

      <div className="container-fluid mt-4 job-post">
        <div className="row">
          <div className="col-12">
            <div className="job-post-head">
              <div>
                <span className="jobs-count">20 </span>
                <span>Job Posts</span>
              </div>
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </div>
            {/* table for recent activity*/}
            <table className="table table-striped">
              <thead>
                {/* table list */}
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Service</th>
                  <th>Customer</th>
                  <th>Location</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {/* table body item1 start */}
                {
                    !loading ? response?.map((job, key) => (
                        <tr>
                          <th scope="row">{key + 1}</th>
                          <td>{moment(job.date).format("DD/MM/YYYY")}</td>
                          <td>{job.time}</td>
                          {/* <td>{job.service.name ? job.service.name : ''}</td> */}
                          <td>Service</td>
                          <td>{job.client?.username ? job.client.username:''}</td>
                          <td>{job?.location ? job.location:''}</td>
                          <td>${job?.price ? job.price:''}</td>
                          <td className="job-post-btn-status">
                            <button className="mr-2 approve">
                              <i className="fa fa-check pr-1" aria-hidden="true"></i>
                              Approve
                            </button>
                            <button className="cancel">
                              <i className="fa fa-times pr-1" aria-hidden="true"></i>
                              Cancel
                            </button>
                          </td>
                        </tr>
                     )
                     )
                        :
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '50%' }}>  <SpinnerLoader /></div>
                }
                {/* table body item1 end */}
              </tbody>
            </table>
            {/* table end */}
          </div>
        </div>
      </div>
      {/* Recent activity section end */}
    </div>
  );
}
