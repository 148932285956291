import "./clientInfoSection.css";
import User from "../../../components/sidebar/icons/User.png";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../utils/BaseUrl";
import SpinnerLoader from "../../loaders/Spiner";
import { Alert } from "react-ionicons";
import AlertMessage from "../../alerts";
import LocationMap from "./LocationMap";
import './LocationMap.css';
const ClientInfoSection = () => {


    const [data, setData] = useState({ clients: 0, artists: 0, bookings: 0, jobs: 0 })
    const [clientInfo, setClientInfo] = useState({ count: 0, newClients: 0 })
    const [artistInfo, setArtistInfo] = useState({ count: 0, newArtists: 0 })
    const [bookingInfo, setBookingInfo] = useState({ today: 0, pre: 0, cancelled: 0 })
    const [jobInfo, setJobInfo] = useState({ count: 0, newJobs: 0 })
    const [locationData,setlocationData]=useState([]);
    const [isLoading, setIsLoading] = useState(true)
    function SetHeader() {
        const token = localStorage.getItem('token')
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    const fetchData = async () => {
        SetHeader()
        const clients = await axios.get(`${baseUrl}/api/dashboard/get-clients-count`);
        const artists = await axios.get(`${baseUrl}/api/dashboard/get-artists-count`);
        const bookings = await axios.get(`${baseUrl}/api/dashboard/get-bookings-count`);
        const jobs = await axios.get(`${baseUrl}/api/dashboard/get-job-posts-count`);
        setClientInfo({ count: clients.data.records.clients_count, newClients: clients.data.records.today_registered_clients })
        setArtistInfo({ count: artists.data.records.artists_count, newArtists: artists.data.records.today_registered_artists })
        setBookingInfo({ today: bookings.data.records.today_bookings, pre: bookings.data.records.pre_bookings, cancelled: bookings.data.records.cancelled_bookings })
        setJobInfo({ count: jobs.data.records.job_posts_count, newJobs: jobs.data.records.today_job_posts })
        setIsLoading(false)
    };
    useEffect(() => {
    fetchData()
    }, []) 
   useEffect(() => {
    SetHeader();
    const Data = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/user/get-artist-live-location`);
            const responseData = response.data;

            if (responseData.errors?.length) {
                AlertMessage("error", 'Unable to fetch data');
            } else {
                const extractedData = responseData.records.map(item => ({
                    image_url: item?.bookings?.artist?.image_url,
                    artistLongitude: item.artist_longitude,
                    artistLatitude: item.artist_latitude,
                }));
                setlocationData(extractedData);
            }
        } catch (error) {
            
            console.error('Error fetching data:', error);
        }
    };
    Data();
}, []);
    
    return <div>
        <div className="container-fluid">
            {/* <Button onClick={getData}>Get Data from API</Button> */}
            <div className="row ">
                <div className="col-6 ">
                    <div className="row">
                        {/* client div start */}
                        <div className="col-6  clients-info-home ">
                            <div className="clients-info-home__header">
                                <img src={User} alt="user" />
                                <span>Clients</span>
                            </div>

                            <center>
                                {isLoading ? <div style={{ height: '80px', paddingTop: '20px' }}><SpinnerLoader /></div> : <h1>{clientInfo.count}</h1>}
                            </center>
                            {!isLoading && <p>{`${clientInfo.newClients} New Clients Today`}</p>}
                        </div>
                        {/* client div end */}
                        {/* artist div start */}
                        <div className="col-5  clients-info-home ml-4">
                            <div className="clients-info-home__header">
                                <img src={User} alt="user" />
                                <span>Artist</span>
                            </div>

                            <center>
                                {isLoading ? <div style={{ height: '80px', paddingTop: '20px' }}><SpinnerLoader /></div> : <h1>{artistInfo.count}</h1>}
                            </center>
                            {!isLoading && <p>{`${artistInfo.newArtists} New comers`} </p>}
                        </div>
                        {/* artist div end */}
                    </div>
                    <div className="row">
                        {/* booking div start */}
                        <div className="col-6  clients-info-home mt-4">
                            <div className="clients-info-home__header">
                                <img src={User} alt="user" />
                                <span>Booking</span>
                            </div>

                            <div
                                style={{
                                    marginTop: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                {isLoading ? <div style={{ height: '80px', paddingTop: '20px' }}><SpinnerLoader /></div> :
                                    <>
                                        <div>
                                            <span className="today ">{bookingInfo.today}</span>
                                            <span className="prebooking leftsideMove">{bookingInfo.pre}</span>
                                            <span className="cancelled leftsideMove">{bookingInfo.cancelled}</span>
                                        </div>

                                        <div>
                                            <span className="booking-text">Today</span>
                                            <span className="booking-text">Prebooking</span>
                                            <span className="booking-text ">Cancelled</span>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        {/* booking div end */}
                        {/* booking div start */}
                        <div className="col-5  clients-info-home mt-4 ml-4">
                            <div className="clients-info-home__header">
                                <img src={User} alt="user" />
                                <span>Job Posts</span>
                            </div>
                            <center>
                                {isLoading ? <div style={{ height: '80px', paddingTop: '20px' }}><SpinnerLoader /></div> : <h1>{jobInfo.count}</h1>}
                            </center>
                            {!isLoading && <p>{`${jobInfo.newJobs} New Today`}</p>}
                        </div>
                        {/* booking div end */}
                    </div>
                </div>
                {/* artist live location */}
                <div className="col-6 artist-live-location">
                    <div className="artist-head">
                        <span>Artist live location</span>
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                    </div>

                    <hr />
                    <div className="map-container">
                       <div className="map-embed">
                        <LocationMap locations={locationData} />
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ClientInfoSection;
