import React from "react";
import "./artistDetail.css";
import { useState } from "react";
import { useEffect } from "react";
import pic from "../artistDetail/profilepic.png";
import rating from "../artistDetail/rating.png";
import Barchart from "../charts/Barchart";
import DoughnutChart from "../charts/Doughnut";
import axios from "axios";
import { baseUrl } from "../../../utils/BaseUrl";
import AlertMessage from "../../../components/alerts";
import moment from "moment";
import SpinnerLoader from "../../loaders/Spiner";
import Reviews from "../Reviews/Review";

export default function ArtistDetail() {
  
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true)
  const [dependecy, setDependency] = useState(0)

  useEffect(() => {
    setLoading(true)
    axios.get(`${baseUrl}/api/artist/new-list`)
      .then(res => {
        if (res.errors?.length) {
          AlertMessage("error", 'Unable to fetch data')
        }
        else {
          if(Object.keys(res.data.records).length == 0){
            setResponse([])
          } else {
            setResponse(res.data.records)
          }
          setLoading(false)
        }
      })
  }, [dependecy, setDependency])
  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <div className="col-8  align">
          {/* artist detail section start */}
          <div className="col-12   artist-detail-main artist__scroll align">
            <div className="artist-detail ">
              <span>Artist Details</span>
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </div>

            {/* table for artish detail*/}
            <table className="table table-striped">
              <thead>
                {/* table list */}
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Join Date</th>
                  <th>Job Done</th>
                  <th>Status</th>
                  <th>Contact no</th>
                  <th>Current Location</th>
                </tr>
              </thead>
              <tbody>
                {/* table body item1 start */}
                {
                  !loading ? response?.map((artist, key) => (
                        <tr>
                          <th scope="row">{key + 1}</th>
                          <td>
                            <div className="artist-img">
                              <img src={artist.absolute_image_url} alt="" />
                              <span>{artist.username}</span>
                            </div>
                          </td>
                          <td>{moment(artist.created_at).format("DD/MM/YYYY")}</td>
                          <td>{artist.bookings_count}</td>
                          <td>
                            <button className="btn-artist-detail-status">
                              Working
                            </button>
                          </td>
                          <td>{artist.phone_no}</td>
                          <td>{artist.address}</td>
                        </tr>
                    )
                  )
                     :
                     <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '50%' }}>  <SpinnerLoader /></div>
                }
                {/* table body item1 end */}
              </tbody>
            </table>
          </div>
          {/* artist detail section end */}
          <div className="row ">
            {/* montly generate section  start*/}
            <div className="col-7  mt-3  artist-detail-main ml-2 ">
              <div className="monthly-revenue mt-2">
                <span>Monthly Generated Revenue</span>
              </div>
              <hr />
              <Barchart />
            </div>
            {/* montly generate section  end*/}
            {/* service status section start */}
            <div className="col-4  mt-3 artist-detail-main ml-4 monthly-revenue">
              <span>Service status</span>
              <DoughnutChart />
            </div>
            {/* service status section end */}
          </div>
        </div>
        {/* customer review section */}
       <Reviews />
      </div>
    </div>
  );
}
