import "./booking.css";
import Pagination from "../../components/users/customers/Pagination";
import Search from "../../components/home/search/search";
import axios from "axios";
import { baseUrl } from "../../utils/BaseUrl";
import { useState } from "react";
import AlertMessage from "../../components/alerts";
import { useEffect } from "react";
import {Button} from 'react-bootstrap';

export default function Booking() {
  
  const [bookings, setBookings] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(10)
  function SetHeader() {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  }  
  useEffect(() => {
    SetHeader();
    axios.post(`${baseUrl}/api/booking/list-all?page=${currentPage}`)
      .then(res => {
        if (res.data.errors?.length) {
          AlertMessage('error', 'Unable to fetch data')
        }
        else {
          setBookings(res.data.records.data)
          setCurrentPage(res.data.records.current_page)
          setLastPage(res.data.records.last_page)
          setLoading(false)
        }
      })
  }, [currentPage])
  return (
    <div className="booking">
      <Search />
      <div>
        <span style={{ color: "#7A7A7A" }} className="pl-1">
          Bookings
        </span>
        {/* <div className="float-right inputfilter">
          <span>show</span>
          <span>
            <input type="text" className="inpEntries " />
          </span>
          <span>Entries</span>
        </div> */}
      </div>
      {/* card */}
      <div className="card card-outline-secondary booking__services mt-2 ">
        {/* heading */}
        <div className="card-header">
          <h3 className=" mb-0 ">Booking services</h3>
        </div>
        {/* body */}
        <div>
          {/* table */}
           {loading ? (
        <p>Loading...</p>
      ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Booking ID</th>
                <th>Order ID</th>
                <th>Client</th>
                <th>Service</th>
                <th>Time</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Location</th>
                <th>Booked Artist</th>
                <th>Booking Status</th>
              </tr>
            </thead>
            <tbody>
            {bookings.map(booking => (
              <tr key={booking.id}>
                <td>{booking.id}</td>
                <td>{booking.id}</td>
                <td>{booking.client?.username ? booking.client.username : '-'}</td>
                <td>{booking.booking_service[0]?.name ? booking.booking_service[0].name : '-'}</td>
                <td>{booking.scheduler?.time ? booking.scheduler.time : '-'}</td>
                <td>{booking.ended_at ? booking.ended_at : '-'}</td>
                <td>{booking.total_price ? booking.total_price : '-'}</td>
                <td>{booking.client?.address ? booking.client.address : '-'}</td>
                <td>{booking.artist?.username ? booking.artist.username : '-'}</td>
                <td>
                  <span>
                    <div className="circle-booking-view">
                     <Button variant="info">{booking?.status ? booking.status : 'N/A'}</Button>
                    </div>
                    {/* <div className="circle-booking-edit">
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </div>
                  </span>
                  <span>
                    <div className="circle-booking-cross">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </div> */}
                  </span>
                </td>
              </tr>
            ))}
              {/* row end */}
            </tbody>
          </table>
      )}
        </div>
        {/* pagination */}
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} lastPage={lastPage} />
        {/* <Pagination /> */}
      </div>
    </div>
  );
}
