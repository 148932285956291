import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../utils/BaseUrl';
import AlertMessage from '../../../components/alerts';

function PublishButton(props) {
  const [isPublishing, setIsPublishing] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [dealStatus, setDealStatus] = useState('');
  const [isDealPublished, setIsDealPublished] = useState(false);

  useEffect(() => {
    setButtonText(props.deal.deal.status === 'active' && props.deal.deal.is_published === 1
      ? 'Published'
      : 'Unpublish'
    );
    setDealStatus(props.deal.deal.status);
    setIsDealPublished(props.deal.deal.is_published === 1);
  }, [props.deal.deal]);

  const handlePublishClick = () => {
    setIsPublishing(true);

    const newDealStatus = dealStatus === 'active' ? 'inactive' : 'active';
    const newIsDealPublished = !isDealPublished;

    const apiUrl = newDealStatus === 'active'
      ? `${baseUrl}/api/deal/publish/${props.deal.deal.id}`
      : `${baseUrl}/api/deal/unpublish/${props.deal.deal.id}`;

    axios.patch(apiUrl)
      .then(res => {
        console.log(res);
        if (res.data.errors?.length) {
          AlertMessage('error', 'Deal not publish/unpublish');
        } else {
          AlertMessage('success', 'Deal is publish/unpublish');
          setDealStatus(newDealStatus);
          setIsDealPublished(newIsDealPublished);
          setButtonText(newDealStatus === 'active' ? 'Published' : 'Unpublish');
        }
      })
      .catch(error => {
        console.error('An error occurred', error);
      })
      .finally(() => {
        setIsPublishing(false);
      });
  };

  return (
    <Link to="#">
      <button
        className="btn mt-2 mb-2 text-white"
        onClick={handlePublishClick}
        disabled={isPublishing}
      >
        {isPublishing ? 'Updating...' : buttonText}
      </button>
    </Link>
  );
}

export default PublishButton;
