import React from "react";
import Search from "../../components/home/search/search";
import nails from "../users/nails.png";
import "./customerprofile.css";
import pic from "../users/pic.png";
import FavouriteItem from "../../components/users/customers/FavouriteItem";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/BaseUrl";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import AlertMessage from "../../components/alerts";
import artistprofile from "../users/artistprofile.png";
import moment from "moment";
// import { ConstructOutline } from "react-ionicons";
// import { set } from "react-hook-form";
export default function CustomerProfile() {
  const param = useParams()
  const id = param.id
  const history = useHistory()
  const [customer, setCustomer] = useState({})
  const [prebookingData, setPrebookingData] = useState([]);
  const[bookingsDone,setbookingsDone]=useState([])
  const [prebooking,setprebooking]=useState([])
  const [artistServices, setArtistServices] = useState([]);
  const [onRoute,setonRoute]=useState([])
  const [onRouteArtistServices, setonRouteArtistServices] = useState([]);
  const[client,setClient]=useState([])
  const[artist,setArtist]=useState([])
  const[favouriteArtist,setfavouriteArtist]=useState([])
  const googleMapurl=`https://www.google.com/maps/embed/v1/directions?origin=${onRoute?.artist_latitude},${onRoute?.artist_longitude}&destination=${onRoute?.user_latitude},${onRoute?.user_longitude}&key=AIzaSyB1ATljOQdQSbKf_-icbQbfQqBqZlmwD0I`;
  function SetHeader() {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  }
  useEffect(() => {
    SetHeader()
    axios.post(`${baseUrl}/api/user/get-details/`, { id })
      .then(res => {
        setCustomer(res.data.records)
        setPrebookingData(res.data.records.prebooking)
        setArtistServices(res.data.records.done.artist_services);
        setbookingsDone(res.data.records.done.user_bookings);
        if(
          res.data &&
          res.data.records &&
          res.data.records.onRoute &&
          res.data.records.onRoute.bookings.client &&
          res.data.records.onRoute.bookings.client!=null
          ){
        setClient(res.data.records.onRoute.bookings.client);
      } else{
        setClient([]);
      }
      if(
        res.data &&
        res.data.records &&
        res.data.records.onRoute &&
        res.data.records.onRoute.bookings.artist &&
        res.data.records.onRoute.bookings.artist!=null
        ){
        setArtist(res.data.records.onRoute.bookings.artist);}
        else{
          setArtist([]);
        }
        if(
          res.data &&
          res.data.records &&
          res.data.records.onRoute &&
          res.data.records.onRoute.bookings.booking_service &&
          res.data.records.onRoute.bookings.booking_service!=null
          ){
            setonRouteArtistServices(res.data.records.onRoute.bookings.booking_service);
          } else{
            setonRouteArtistServices([]);
          }
        if(
          res.data &&
          res.data.records &&
          res.data.records.onRoute &&
          res.data.records.onRoute!=null
          ){
          setonRoute(res.data.records.onRoute);
          
        }else{
          setonRoute([]);
        }
        if(
          res.data &&
          res.data.records &&
          res.data.records.prebooking &&
          res.data.records.prebooking.user_bookings &&
          res.data.records.prebooking.user_bookings.length > 0
        ){
        setprebooking(res.data.records.prebooking.user_bookings[0])
        
      }
        else{
        setprebooking([])
        }
        
      })

      axios.post(`${baseUrl}/api/user/favourite-artist/`, { id })
      .then(res => {
        if(
          res.data &&
          res.data.records &&
          res.data.records.user_favourite_artist
        ){
          setfavouriteArtist(res.data.records.user_favourite_artist);
        } else {
          setfavouriteArtist([]);
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [])



  const DeleteCustomer = () => {
    axios.post(`${baseUrl}/api/user/delete`, { id })
      .then(res => {
        if (res.data.errors?.length) {
          AlertMessage('error', "Unable To Delete")
        }
        else {
          history.push('/dashboard/customers')
          AlertMessage('success', res.data._metadata.message)
        }
      })
  }
  return (
    <div className="customers">
      <Search />
      <span style={{ color: "#7A7A7A" }} className="pl-1">
        users/customers/customer Detail
      </span>

      <div className="card card-outline-secondary">
        <div className="p-3">
          <div className="row">
            {/* profile pic and info */}
            <div className="col-5 pt-5 artist-profile-detail-div">
              <div className="row artist-image-top">
                <div className="col-4 pt-6 ">
                  {customer.done && customer.done?.image_url ? (
                  <img className="img" src={`https://user.nail2u.net/${customer.done?.image_url}`}  alt="pic" />
                  ) : (
                    <img className="img" src={artistprofile} alt="pic" />
                  )}
                </div>
                <div className="col-8 pt-6">
                  <div className=" pl-1">
                    <h4>{customer.done?.username}</h4>

                    <p className="pt-2">
                      <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                      {customer.done?.email}
                    </p>
                    <p>
                      {" "}
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      {customer.done?.address ? customer.done?.address : 'N/A'}
                    </p>
                    <p>
                      <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                      {customer.done?.phone_no}
                    </p>
                    
                  </div>
                </div>  
              </div>
            </div>
            {/* map on route detail */}
            <div className="col-7">
              {/* heading */}
              <div className="card-header bg-white artist-card-header-info">
                <h6 className=" mb-0 ">Current Status</h6>
                <button className="btn">on route</button>
              </div>
              {/* body */}
              <div>
                <div className="artist-timeline-table">
                  {/* google map */}
                  <div className="mt-4">
                    <iframe
                      title="customer-location"
                      width="400"
                      height="240"
                      // frameborder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginidth="0"
                      src={googleMapurl}
                      >
                      <a href="http://www.gps.ie/">sport gps</a>
                    </iframe>
                  </div>
                  {/* table */}
                  <div>
                    <table className="table artist-table">
                      <thead>
                        <th>Amount</th>
                        <th>Booking Number</th>
                        <th>Service Type</th>
                        <th>Distance</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>${onRoute && onRoute.bookings ? onRoute.bookings?.total_price : '0'}</td>
                          <td>{onRoute && onRoute.booking_id ? onRoute?.booking_id : 'N/A'}</td>
                          <td> {onRouteArtistServices && onRouteArtistServices.length > 0 ? (
                          onRouteArtistServices.map((item, index) => (
                                 <p key={index}>{item.name},</p>
                          ))
                        ) : (
                          <p>No Service</p>
                        )}</td>
                          <td>N/A</td>
                        </tr>
                      </tbody>
                    </table>
                    {/* timeline  start*/}
                    <div className="timeline-artist-location">
                      <div>
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        <h5>Customer’s Location</h5>
                        <p>{client && client.address ? client?.address : 'N/A'}</p>
                      </div>
                      <div>
                        <h5>Time Left</h5>
                        <p>N/A</p>
                      </div>
                      <div>
                        <h5>Artist’s Location </h5>
                        {artist && artist.street_name && artist.city && artist.state && artist.zipcode ?
                        <p>{artist?.street_name},{artist?.city},{artist?.state},{artist?.zipcode}</p>
                        : <p>N/A</p>
                        }
                        </div>
                    </div>
                    {/* timeline  end*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* section 2 */}
        <div className="row">
          <div className="col-5">
            <div className="card card-outline-secondary ml-2 pre__booking">
              {/* heading */}
              <div className="card-header">
                <h3 className=" mb-0 ">Pre booking</h3>
              </div>
              {/* body */}
              <div className="p-2">
                <div className="pre-booking-customer-div">
                  <div className="customer-prebooking ">
                    <img src={nails} alt="" />
                    <h4>Acrylic Deal</h4>
                    <h5>Services</h5>
                    {artistServices && artistServices.length > 0 ? (
                          artistServices.map((item, index) => (
                                 <p key={index}>{item.name},</p>
                          ))
                        ) : (
                          <p>No Service</p>
                        )}

                  </div>
                  <div>
                    <div className="prebooking-customer-info">
                      <div>
                        <h5>Order ID : </h5>
                        {/* <h5>Service ID :</h5> */}
                        <h5>Scheduled Time :</h5>
                        <h5>Scheduled Date :</h5>
                        {/* <h5>Tax :</h5> */}
                        <h5>Subtotal : </h5>
                        <h5>Payment :</h5>
                      </div>
                      <div>
                        <p>{prebooking && prebooking.id ? prebooking?.id : '-'}</p>
                        {/* <p>23425433</p> */}
                        <p>{prebooking && prebooking.scheduler ? prebooking.scheduler?.time : '00:00'}</p>
                        <p>{prebooking && prebooking.created_at ? moment(prebooking?.created_at).format('DD-MM-YY'): '00-00-00'}</p>
                        {/* <p>$0.15</p> */}
                        <p>{prebooking && prebooking.bookings ? prebooking.bookings?.total_price : '$0'}</p>
                        <p>{prebooking && prebooking.bookings ? prebooking.bookings?.total_price : '$0'}</p>
                      </div>
                    </div>
                    <div className="prebooking-customer-profile">
                      {/* <img src={pic} alt="" /> */}
                      {prebooking && prebooking.artist && prebooking.artist?.image_url ? (
                      <img src={`https://user.nail2u.net/${prebooking.artist?.image_url}`} alt="pic" />
                      ) : (
                        <img src={pic} alt="" />
                      )}
                      <div>
                        <h5>{prebooking && prebooking.artist ?prebooking.artist?.username : '-'}</h5>
                        <h5>{prebooking && prebooking.artist ?prebooking.artist?.phone_no : '-'}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-7 ">
            <div className="card card-outline-secondary mr-2 previous__booking ">
              {/* heading */}
              <div className="card-header">
                <h3 className=" mb-0 ">Previous Booking</h3>
              </div>
              {/* body */}
              <div className="previous-booking-table-div">
                <table className="table table-striped previous-booking-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Order ID</th>
                      {/* <th>Service ID</th> */}
                      <th>Card no.</th>
                      <th>Artist</th>
                      <th>Location</th>
                      <th>Date/time</th>
                      <th>Payment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* item row 1 */}
                    { bookingsDone && bookingsDone.length > 0 ? (
                          bookingsDone.map((item, index) => (
                            <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.id}</td>
                      {/* <td>A9B11C3D</td> */}
                      <td>0011 2342 3219</td>
                      <td>{item.artist?.username}</td>
                      <td>{item.artist?.street_name} {item.artist?.city}, {item.artist?.state} {item.artist?.zipcode} </td>
                      <td>{moment(item.artist?.created_at).format('DD-MM-YY')}</td>
                      <td>${item?.total_price}</td>
                    </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8">No Booking</td>
                          </tr>
                        )
}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* favourite section start */}

        <div className="ml-2 mr-2 mt-2">
          <div className="card card-outline-secondary favourite">
            {/* heading */}
            <div className="card-header">
              <h3 className=" mb-0 ">Favourite</h3>
            </div>
            {/* body */}
            <div>
              {/* carousel data start here */}
              <div className="container">
                <div className="row">
                {favouriteArtist.length > 0 && ( 
                  <div
                    id="carousel"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    {/* <ol className="carousel-indicators">
                    <li
                      data-target="#carousel"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li data-target="#carousel" data-slide-to="1"></li>
                  </ol> */}
                    <div className="carousel-inner">
                      {/* second round */}
                      <div className="carousel-item active">
                        <div className="d-none d-lg-block"> 
                        <div className="slide-box">
                          {/* items box here  start*/}
                          { favouriteArtist.map((artist, index) => (
                            index < 4 &&  <FavouriteItem artist={artist?.artist}/>                             
                          )) }
                           

                          {/* items box end */}
                        </div>
                        </div>
                      </div>
                      {/* second round end */}
                                {favouriteArtist.slice(4).reduce((slideChunks, artist, index) => {
                                      if (index % 4 === 0) {
                                        slideChunks.push([artist]);
                                          } else {
                                        slideChunks[slideChunks.length - 1].push(artist);
                                        }
                                      return slideChunks;
                                    }, []).map((slideItems, slideIndex) => (
                                  <div className="carousel-item" key={slideIndex}>
                                    <div className="slide-box">
                                      {slideItems.map((artist, index) => (
                                        <FavouriteItem artist={artist?.artist} key={index} />
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#carousel"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      >
                        <i
                          className="fa fa-chevron-left"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#carousel"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                  )}
                </div>
              </div>
              {/* carousel data end here */}
            </div>
          </div>
        </div>
        {/* favourite section end */}
      </div>
    </div>
  );
}
