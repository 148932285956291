import React, { useState } from "react";
import apic from "../users/artists/a.png";
import trash from "../users/artists/trash.png";
import p from "../users/artists/g.jpg";
import s from "../users/artists/s.png";
import StarRating from 'react-bootstrap-star-rating';
import SpinnerLoader from "../loaders/Spiner";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/BaseUrl";
import AlertMessage from "../alerts";
import { Height } from "@material-ui/icons";


const ArtistCard = (props) => {

    const [deleteArtistLoader, setDeleteArtistLoader] = useState(false);
    const history = useHistory();

    function SetHeader() {
        const token = localStorage.getItem('token')
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    const handleDeleteArtist = (id) => {
        SetHeader();
        setDeleteArtistLoader(true)
        axios.post(`${baseUrl}/api/artist/delete`, { id })
            .then(res => {
                console.log('res', res)
                if (res.data.errors?.length) {
                    AlertMessage('error', "Unable to Delete")
                    setDeleteArtistLoader(false)
                }
                else {
                    setDeleteArtistLoader(false)
                    AlertMessage('success', res.data._metadata.message)
                    props.setDependency(props.dependency + 1)
                }
            })
    }

    const handleArtistId = (id) => {
        history.push(`/dashboard/artists/artist-profile/${id}`)
    }

    return (<div className="card m-4 artistlist">
        <img className="card-img-top" src={`https://artist.nail2u.net/${props.coverImage}`} alt="Card" />
        <span className="toptrash">
            {(deleteArtistLoader) ? <SpinnerLoader /> :
                <img style={{ cursor: 'pointer' }} src={trash} onClick={() => handleDeleteArtist(props.artistId)} />}
        </span>
        <div className="card-body artist">
            <img src={`https://artist.nail2u.net/${props.profileImage}`}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = s;
                }}
                className="artist-profile" />

            <p className="card-title text-center mt-3 artist-name">
                {props.username}
            </p>
            <span className="arating mb-2">
                <StarRating
                    defaultValue={props.rating.toFixed(1)} />

            </span>
            <p className="card-text artist-dec ">
                Expert in Acrylic and French Manicure
            </p>
            <span>Jobs Done</span>
            <span className="float-right">Working Since</span>
            <br />
            <span className="ml-4 num">{props.bookingsCount}</span>
            <span className="float-right num mr-5">{props.workingSince}</span>
            <p className="text-center para">
                <i className="fa fa-phone" aria-hidden="true"></i>
                <span>{props.phoneNo}</span>
            </p>

            <button
                type="button"
                className="btn btn-artist-info "
                onClick={() => handleArtistId(props.artistId)}>View Artist
            </button>

        </div>
    </div>);
}


export default ArtistCard;