import "./customerDetail.css";
import Pagination from "./Pagination";
import Search from "../../home/search/search";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../utils/BaseUrl";
import AlertMessage from "../../alerts";
import SpinnerLoader from "../../loaders/Spiner";
import moment from "moment";
export default function CustomerDetails() {


  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(10)
  const [customers, setCustomers] = useState()
  const [loading, setLoading] = useState(true)

  function SetHeader() {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  }
  SetHeader();
  useEffect(() => {
    setLoading(true)
    axios.post(`${baseUrl}/api/user/list-all?page=${currentPage}`)
      .then(res => {
        if (res.errors?.length) {
          AlertMessage("error", 'Unable to fetch data')
        }
        else {
          setCustomers(res.data.records.data)
          setCurrentPage(res.data.records.current_page)
          setLastPage(res.data.records.last_page)
          setLoading(false)
        }
      })
  }, [currentPage])
  return (
    <div>
      <Search />
      {/* <div>
        <span style={{ color: "#7A7A7A" }} className="pl-1">
          users/customers
        </span>
        <div className="float-right inputfilter">
          <span>show</span>
          <span>
            <input type="text" className="inpEntries " />
          </span>
          <span>Entries</span>
        </div>
      </div> */}
      {/* card */}
      <div className="card card-outline-secondary mt-2 customer__deatail__table">
        {/* heading */}
        <div className="card-header">
          <h3 className=" mb-0 ">All Customers</h3>
        </div>
        {/* body */}
        <div>
          {/* table */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Time</th>
                <th>Date</th>
                <th>Email Address</th>
                <th>Location</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? <div style={{ height: '455px', padding: '0% 550%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerLoader /></div> : customers?.map(customer => (
                <tr>
                  <td>{customer.id}</td>
                  <td>{customer.username}</td>
                  <td>{moment(customer.created_at).format("hh:mm a")}</td>
                  <td>{moment(customer.created_at).format("DD/MM/YYYY")}</td>
                  <td>{customer.email}</td>
                  <td>{customer.address ? customer.address : 'N/A'}</td>
                  <td style={{ display: "flex", alignItems: "center" }}>
                    <Link to={`/dashboard/customers/customer-profile/${customer.id}`} >
                      {" "}
                      View Detail{" "}
                    </Link>

                    <i
                      style={{ color: "#FF5050" }}
                      className="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} lastPage={lastPage} />
      </div>
    </div>
  );
}
