import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./App.css";
import Home from "./pages/home/Home";
import Customers from "./pages/users/Customers";
import Booking from "./pages/booking/Booking";
import DailyDeal from "./pages/services/DailyDeal";
import AllServices from "./pages/services/AllServices";
import Team from "./pages/team/Team";
import Chat from "./pages/chat/Chat";
import Admin from "./pages/admin/Admin";
import Setting from "./pages/setting/Setting";
import Artists from "./pages/users/Artists";
import Pcustomers from "./pages/payment/Pcustomers";
import Partists from "./pages/payment/Partists";
import ArtistProfile from "./pages/users/ArtistProfile";
import Login from "./pages/auth/Login";


import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import CustomerProfile from "./pages/users/CustomerProfile";
import AdminProfile from "./pages/admin/AdminProfile";


//layouts
import { LayoutLogin, LayoutBasic } from './layouts/layout';
import { ProtectedRoute } from "./components/middleware";

import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function Dashborad() {
       const location = useLocation();
       const history = useHistory();

       function getToken() {
              return localStorage.getItem("token");
       }

       const isAuthorized = () => {
              return getToken() !== null ? true : false;
       };
       useEffect(() => {
              const authorized = isAuthorized();

              if (authorized && location.pathname == "/login") {
                     history.push("/dashboard");
              }
              if (!authorized && location.pathname !== "/login") {
                     history.push("/login");
              }
       }, [history, location]);
       return (
              <Switch>
                     <Route exact path="/" render={() => <Redirect to="/login" />} />
                     <Route exact path="/login"
                            render={() => <LayoutLogin><Login /></LayoutLogin>}
                     />
                     <Route exact path="/dashboard"
                            render={() => <LayoutBasic><Home /></LayoutBasic>}
                     />
                     <Route exact path="/dashboard/customers"
                            render={() => <LayoutBasic><Customers /></LayoutBasic>}
                     />
                     <Route exact path="/dashboard/customers/customer-profile/:id"
                            render={() => <LayoutBasic><CustomerProfile /></LayoutBasic>}
                     />
                     <Route exact path="/dashboard/artists"
                            render={() => <LayoutBasic><Artists /></LayoutBasic>}
                     />

                     <Route exact path="/dashboard/artists/artist-profile/:id"
                            render={() => <LayoutBasic><ArtistProfile /></LayoutBasic>}
                     />
                     <Route path="/dashboard/booking"
                            render={() => <LayoutBasic><Booking /></LayoutBasic>}
                     />
                     <Route path="/dashboard/daily-deals"
                            render={() => <LayoutBasic><DailyDeal /></LayoutBasic>}
                     />
                     <Route path="/dashboard/all-services"
                            render={() => <LayoutBasic><AllServices /></LayoutBasic>}
                     />
                     <Route path="/dashboard/payments/customers"
                            render={() => <LayoutBasic><Pcustomers /></LayoutBasic>}
                     />
                     <Route path="/dashboard/payments/artists"
                            render={() => <LayoutBasic><Partists /></LayoutBasic>}
                     />
                     <Route path="/dashboard/team"
                            render={() => <LayoutBasic><Team /></LayoutBasic>}
                     />
                     <Route path="/dashboard/chat"
                            render={() => <LayoutBasic><Chat /></LayoutBasic>}
                     />
                     <Route exact path="/dashboard/admin"
                            render={() => <LayoutBasic><Admin /></LayoutBasic>}
                     />
                     <Route exact path="/dashboard/admin/admin-profile/:id"
                            render={() => <LayoutBasic><AdminProfile /></LayoutBasic>}
                     />
                     <Route path="/dashboard/setting"
                            render={() => <LayoutBasic><Setting /></LayoutBasic>}
                     />
              </Switch>
       );
}
